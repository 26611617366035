import {
  DEFAULT_TOTALS,
  Order,
  OrderItem,
  OrderItemsTotal,
  RefundInformation,
  Tax
} from "@/interfaces/order";
import { getHeaders } from "@/metadata/refundOrders";
import { orderService } from "@/services/order.service";
import {
  MultiSelectConfig,
  TableComponent,
  TableHeader,
  TableSelectEvent
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import reduce from "lodash/reduce";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./RefundOrderModal.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  }
})
export default class RefunOrderdModal extends Vue {
  @Prop({ required: true })
  public order!: Order;
  @Prop({ required: true })
  public orderItems!: OrderItem[];
  @Prop({ required: true })
  public itemsSelected!: OrderItem[];
  @Prop({ required: true })
  public itemsSelectedInfo!: RefundInformation;

  public refundInformation: RefundInformation | null = null;
  public headers: TableHeader[] = [];
  public selectedItemsIds: number[] = [];
  public cloneItemsSelected: OrderItem[] = [];
  public cloneInfoItemsSelected: RefundInformation = {
    ...this.itemsSelectedInfo
  };
  public cloneOrderItems: OrderItem[] = [];
  public loading = false;
  public processing = false;
  public multiSelectionConfig: MultiSelectConfig = {
    disableCondition: item => !Boolean(item.quantityToRefund),
    tooltip: "refund_retail_sales.messages_item_select_refund"
  };

  public orderItemsTotal: OrderItemsTotal = {
    available: {},
    price_final: 0,
    price_raw: 0,
    quantity: {},
    quantityToRefund: {},
    refund: {},
    tax_amount: 0,
    totalPricesRefundItems: 0,
    unitPriceForRefund: 0,
    wholeOrderTaxes: 0,
    refund_subtotal: 0,
    refund_sub_total: 0,
    subtotal: 0
  };

  /**
   * Gets refund quantity of selected items by unit
   * @returns string in formata 1u or 3u / 6g
   */
  public get selectedRefundQuantity() {
    const reduceQuantity = this.reduceQuantityToRefund(this.itemsSelected);
    return this.formatMultiUnit(reduceQuantity);
  }

  /**
   * Gets total price of selected items
   * If all items are selected the whole ordet taxes are added
   * @returns number of total added
   */
  public get selectedTotalPrice() {
    const selectedTotal =
      (this.cloneInfoItemsSelected &&
        this.cloneInfoItemsSelected.refund_total) ||
      0;
    return this.allItemsSelected
      ? selectedTotal + this.orderItemsTotal.wholeOrderTaxes
      : selectedTotal;
  }

  /**
   * Gets if all items are selected
   * @returns boolean
   */
  public get allItemsSelected() {
    return this.itemsSelected.length === this.cloneOrderItems.length;
  }

  public get selectedWholeOrderTax() {
    return (
      this.cloneInfoItemsSelected &&
      this.cloneInfoItemsSelected.per_transaction_taxes.reduce((sum, t) => {
        sum += t.amount;
        return sum;
      }, 0)
    );
  }
  public get totalWholeOrderTax() {
    return (
      this.refundInformation &&
      this.refundInformation.per_transaction_taxes.reduce((sum, t) => {
        sum += t.amount;
        return sum;
      }, 0)
    );
  }

  public debounceOnSelection = debounce(
    async (event: TableSelectEvent, context) => {
      context.selectedItemsIds = (event.currentSelection as OrderItem[]).reduce(
        (acc: number[], item) => {
          if (item.available) {
            acc.push(item.id!);
          }
          return acc;
        },
        []
      );
      context.cloneItemsSelected = event.currentSelection.filter(
        i => i.available
      );
      context.processing = true;
      await context.getTotalsPerSelection();
      context.$emit("updateSelecteds", context.cloneItemsSelected);
      context.processing = false;
    },
    500
  );

  public async syncSelectedItems() {
    if (this.processing) {
      // serves the same purpose as debounce
      return;
    }
    this.processing = true;
    this.cloneItemsSelected = this.cloneOrderItems.filter(item =>
      this.selectedItemsIds.includes(item.id!)
    );
    await this.getTotalsPerSelection();
    this.$emit("updateSelecteds", this.cloneItemsSelected, () => {
      this.processing = false;
    });
  }

  public async getTotalsPerSelection() {
    let infoSelected = null;
    if (this.cloneItemsSelected.length) {
      infoSelected = await orderService.refundInformation(
        this.order.id!,
        this.cloneItemsSelected!
      );
    }
    this.cloneInfoItemsSelected = infoSelected || { ...DEFAULT_TOTALS };
  }

  public async setPricesItems() {
    this.refundInformation = await orderService.refundInformation(
      this.order.id!,
      this.cloneOrderItems!
    );
    this.cloneOrderItems = this.cloneOrderItems!.map(i => {
      const currentElement = this.refundInformation!.items!.find(
        (p: { order_item_id: number }) => i.id === p.order_item_id
      )!;
      return {
        ...i,
        totalPricesRefundItems:
          (currentElement &&
            Math.trunc(currentElement.refund_total * 100) / 100) ||
          0,
        unitPriceForRefund:
          (currentElement &&
            Math.trunc(currentElement.price_per_item * 100) / 100) ||
          0
      };
    });
  }

  public async created() {
    this.loading = true;
    this.cloneOrderItems = cloneDeep(this.orderItems);
    this.cloneItemsSelected = cloneDeep(this.itemsSelected);
    this.cloneInfoItemsSelected = cloneDeep(this.itemsSelectedInfo);
    this.selectedItemsIds = this.cloneItemsSelected.map(item => item.id || 0);
    await this.setPricesItems();
    this.headers = getHeaders(this.syncSelectedItems);
    this.initTotals();
    this.loading = false;
  }

  /**
   * Initializes orderItemsTotal
   * Adding all values and calculating the whole order tax
   */
  public initTotals() {
    this.orderItemsTotal = this.cloneOrderItems.reduce(
      (orders, orderItem: OrderItem): OrderItemsTotal => {
        const productUnit = orderItem.product_info!.product_unit;
        orders.available[productUnit] = this.sumToTotal(
          orders.available[productUnit],
          orderItem.available!
        );
        orders.quantity[productUnit] = this.sumToTotal(
          orders.quantity[productUnit],
          orderItem.quantity!
        );
        orders.quantityToRefund[productUnit] = this.sumToTotal(
          orders.quantityToRefund[productUnit],
          orderItem.quantityToRefund!
        );
        orders.refund[productUnit] = this.sumToTotal(
          orders.refund[productUnit],
          orderItem.refund!
        );
        orders.unitPriceForRefund = this.sumToTotal(
          orders.unitPriceForRefund,
          orderItem.unitPriceForRefund!
        );
        orders.totalPricesRefundItems = this.sumToTotal(
          orders.totalPricesRefundItems,
          orderItem.totalPricesRefundItems!
        );
        orders.tax_amount = this.sumToTotal(
          orders.tax_amount,
          orderItem.tax_amount!
        );
        orders.refund_subtotal = this.sumToTotal(
          orders.refund_subtotal!,
          orderItem.refund_subtotal!
        );
        orders.subtotal = this.sumToTotal(
          orders.subtotal!,
          orderItem.subtotal!
        );
        orders.price_final = this.sumToTotal(
          orders.price_final,
          orderItem.price_final!
        );
        orders.wholeOrderTaxes = this.calculatePerTransactionTax(
          orderItem.taxes!,
          orders.wholeOrderTaxes,
          orders.price_raw
        );
        return orders;
      },
      this.orderItemsTotal
    );
  }

  public async setItemsToReturn() {
    this.$emit("resolve", {
      itemsSelected: this.itemsSelected,
      infoItemsSelected: this.cloneInfoItemsSelected
    });
  }

  public nextStep() {
    this.$emit("selectRefund", {
      orderItems: this.cloneOrderItems,
      itemsSelected: this.cloneItemsSelected,
      infoItemsSelected: this.cloneInfoItemsSelected
    });
  }

  public closeModal() {
    this.$emit("cancel");
  }

  public onSelection(event: TableSelectEvent) {
    this.debounceOnSelection(event, this);
  }

  protected reduceQuantityToRefund(arrayOfItems: OrderItem[]) {
    const cloneOfItems = cloneDeep(arrayOfItems);
    return cloneOfItems.reduce((units: { [key: string]: number }, ofItem) => {
      const productUnit = ofItem.product_info!.product_unit;
      units[productUnit] =
        (units[productUnit]
          ? units[productUnit] + Number(ofItem.quantityToRefund!)
          : Number(ofItem.quantityToRefund!)) || 0;
      return units;
    }, {});
  }
  protected formatMultiUnit(unitsObj: { [key: string]: number }): string {
    return reduce(
      unitsObj,
      (formattedText: string, amount, unit) => {
        const totalAmount = Math.trunc(amount * 100) / 100;
        const separator = formattedText.length > 0 ? " / " : "";
        return formattedText + separator + totalAmount + unit;
      },
      ""
    );
  }

  protected calculatePerTransactionTax(
    taxes: Tax[],
    wholeOrderTaxes: number,
    rawPrice: number
  ) {
    const perTransactionTax =
      (taxes &&
        taxes
          .filter(tax => {
            return tax.tax_type === "PER_TRANSACTION";
          })
          .reduce((taxRate, tax) => {
            return taxRate + tax.rate!;
          }, 0)) ||
      0;
    const totalTax = perTransactionTax / 100;
    return this.sumToTotal(wholeOrderTaxes, rawPrice * totalTax);
  }

  protected sumToTotal(total: number, newValue: number) {
    return (total ? total + newValue : newValue) || 0;
  }
}
