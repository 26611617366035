import { Order } from "@/interfaces/order";
import {
  MetrcDetail,
  MetrcDetailOperation,
  TrcInformation,
  TrcInformationTransaction
} from "@/interfaces/traceability";
import { messagesService } from "@/services/messages.service";
import { traceabilityService } from "@/services/traceability.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./MetrcDetails.template.vue";

@Component({
  mixins: [Template],
  filters: {
    snakeToPascal(value: string) {
      return value
        .split("_")
        .map((str: string) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        })
        .join("");
    }
  }
})
export class MetrcDetailsComponent extends Vue {
  @Prop() public order!: Order & { order_number: string };
  @Prop({ default: false })
  public isRefund!: boolean;
  public isLoading: boolean = false;
  public metrcDetail!: MetrcDetail;
  public trcInformation!: TrcInformation | null;
  public transactions: TrcInformationTransaction[] = [];

  public close() {
    this.$emit("reject");
  }

  protected async created() {
    this.isLoading = true;
    this.trcInformation = null;

    const response = await traceabilityService.getMetrcOrderDetails(
      this.order!.id || 0,
      this.isRefund
    );

    if (response) {
      this.setMetrcDetail(response.order || response.order_refund);

      if (response.trc_information) {
        this.metrcDetail.metrc_id = response.trc_information.id;
        const trcInformation = { ...response.trc_information };
        delete trcInformation.id;
        delete trcInformation.transactions;

        this.trcInformation = trcInformation;
        this.transactions = response.trc_information.transactions;
      } else {
        messagesService.showMessage(
          "fas fa-exclamation-circle",
          "metrc_details.not_loaded",
          "error"
        );
      }
    } else {
      this.setMetrcDetail(response.order);
    }

    this.isLoading = false;
  }

  protected setMetrcDetail(operation: MetrcDetailOperation) {
    this.metrcDetail = {
      biotrack_ticket_id:
        operation.order_number || operation.operation_uid || "",
      biotrack_datetime: operation.sold_at || operation.created_at || ""
    };
  }
}
