import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import ConfirmModalComponent from "@/components/sharedComponents/confirm/confirm.component";
import { policyList } from "@/enums/permissions";
import { EventBus } from "@/event-bus";
import { ProductVariant } from "@/interfaces/batch";
import { Location } from "@/interfaces/location";
import {
  defaultFilterModel,
  FilterModel,
  Order,
  Refund,
  RefundHistoryItem
} from "@/interfaces/order";
import { RetailSettings } from "@/interfaces/retailSettings";
import { i18n } from "@/plugins/i18n";
import { messagesService } from "@/services/messages.service";
import { metrcSalesActionService } from "@/services/metrcSales.action.service";
import { orderService } from "@/services/order.service";
import { pmpIntegrationService } from "@/services/pmpIntegration.service";
import { traceabilityService } from "@/services/traceability.service";
import { Callback, PageNavAction } from "@/types/types";
import differenceInMinutes from "date-fns/differenceInMinutes";
import {
  BooleanCheck,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import RefundHistoryComponent from "./RefundHistory/RefundHistory.component";
import Template from "./RetailSalesHistory.template.vue";
import SalesHistoryComponent from "./Sales/SalesHistory.component";

interface CurrentPagination {
  page: number;
  per_page: number;
}

const QUERY_DEFAULT = {
  filters: { ...defaultFilterModel },
  pagination: { page: 1, per_page: 10 }
};
@Component({
  mixins: [Template],
  components: {
    SalesHistoryComponent,
    RefundHistoryComponent
  }
})
export default class RetailSalesHistoryComponent extends Vue {
  @Action("setCurrentTill", { namespace: "UserModule" })
  public setCurrentTill!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("metrcEnabled", { namespace: "AuthModule" })
  public hasMetrc!: boolean;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  public pmpIntegration: boolean = false;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;

  public paginate = orderService.paginationAction();
  public currentTab = "sales";
  public refunds: Refund[] = [];
  public orders: Order[] = [];
  public paginationRefunds: TablePagination = { ...TablePaginationDefault };
  public paginationOrders: TablePagination = { ...TablePaginationDefault };
  public loadingOrder = false;
  public loadingRefund = false;
  public metrcOrderLatency = 5;
  // This Flag saleIsPerformed is used to determine if the new sale is performed , if it performed we need to call refund API Again.
  public saleIsPerformed = false;

  public onChangeTab() {
    if (this.currentTab === "sales" && !this.orders.length) {
      this.getOrders(QUERY_DEFAULT);
    }
    if (
      this.currentTab === "refund" &&
      (!this.refunds.length || this.saleIsPerformed)
    ) {
      this.getRefunds(QUERY_DEFAULT);
    }
    this.setTabRoute();
  }

  public parseFilters(
    filter: FilterModel,
    pagination: CurrentPagination,
    type: string
  ) {
    const query: { [key: string]: any } = {
      [`q[${
        type === "SALE"
          ? "sold_at_greater_than_or_equal"
          : "created_at_greater_than_or_equal"
      }]`]: filter.startDate!,
      [`q[${
        type === "SALE"
          ? "sold_at_less_than_or_equal"
          : "created_at_less_than_or_equal"
      }]`]: filter.endDate!,
      "q[customer_id_eq]": filter.customer,
      "q[user_id_is_in]": (filter.users.length && filter.users) || [],
      "q[trc_status_eq]": filter.trcStatus,
      [`q[${
        type === "SALE" ? "preOrder.source_eq" : "order.preOrder.source_eq"
      }]`]: filter.source,
      "q[preOrder.oauth_app_id_is_in]":
        (filter.vendor.length && filter.vendor) || [],
      sort: filter.sort
    };
    if (this.currentTab === "sales") {
      if (filter.pmpStatus === "NOT_UPLOADED") {
        query["q[orderItems.pmp_upload_status_is_in]"] = [
          "NOT_UPLOADED",
          "ERROR"
        ];
      } else {
        query["q[orderItems.pmp_upload_status_is_in][]"] = filter.pmpStatus;
      }
    } else if (this.currentTab === "refund") {
      if (filter.pmpStatus === "NOT_UPLOADED") {
        query["q[items.orderItem.pmp_upload_status_is_in]"] = [
          "NOT_UPLOADED",
          "ERROR"
        ];
      } else {
        query["q[items.orderItem.pmp_upload_status_is_in][]"] =
          filter.pmpStatus;
      }
    }
    const TransactionIDKey =
      this.currentTab === "sales"
        ? "q[order_number_contains]"
        : "q[operation_uid_contains]";
    query[TransactionIDKey] = filter.transactionId;
    const validFields = Object.keys(query).filter((key: string) => query[key]);
    return {
      ...pick(query, validFields),
      page: pagination.page,
      per_page: pagination.per_page
    };
  }

  public async getOrders(data: {
    filters: FilterModel;
    pagination: CurrentPagination;
  }) {
    if (this.currentTab === "refund") {
      this.currentTab = "sales";
    }
    this.loadingOrder = true;
    const orders = await orderService.getOrdersPaid(
      this.parseFilters(data.filters, data.pagination, "SALE")
    );
    if (orders) {
      this.orders = orders;
      EventBus.$emit("Orders_List", this.orders);
      this.paginationOrders = cloneDeep(await orderService.getPagination());
    }
    if (this.orders) {
      this.orders.map(order => {
        order.order_items!.map(orderItem => {
          if (
            this.hasBioTrackTraceIntegrations &&
            this.bioTrackTraceEnabled &&
            orderItem.biotrack_traceability_id !== null
          ) {
            orderItem.batch_barcode_uid = orderItem.biotrack_traceability_id;
          }
        });
      });
    }
    this.saleIsPerformed = true;
    this.loadingOrder = false;
  }

  public setOrdersLoading(isLoading: boolean) {
    this.loadingOrder = isLoading;
  }

  public async getRefunds(data: {
    filters: FilterModel;
    pagination: CurrentPagination;
  }) {
    this.loadingRefund = true;
    const refunds = await orderService.getRefunds(
      this.parseFilters(data.filters, data.pagination, "REFUND")
    );

    if (refunds) {
      this.refunds = refunds;
      this.paginationRefunds = cloneDeep(await orderService.getPagination());
    }

    if (this.refunds) {
      this.refunds.map(refund => {
        refund.items!.map(refundItem => {
          if (
            this.hasBioTrackTraceIntegrations &&
            this.bioTrackTraceEnabled &&
            refundItem.order_items!.biotrack_traceability_id !== null
          ) {
            refundItem.order_items!.batch_barcode_uid = refundItem.order_items!.biotrack_traceability_id;
          }
        });
      });
    }
    this.saleIsPerformed = false;
    this.loadingRefund = false;
  }

  public addOrder(data: { operationUid: string; order: Order }) {
    if (data.order) {
      const index = this.refunds.findIndex(
        r => r.operation_uid === data.operationUid
      );
      this.refunds[index] = {
        ...this.refunds[index],
        order: data.order,
        items: this.refunds[index].items.map(i => {
          const orderItem = data.order.order_items!.find(
            oi => oi.id === i.order_item_id
          );
          return {
            ...i,
            tracking_id: orderItem!.tracking_id || "--",
            productName: orderItem!.product_info!.name,
            quantityFormated: `${i.quantity}${orderItem!.product_info &&
              orderItem!.product_info.product_unit}`
          };
        })
      };
    }
  }

  public async validateTime(orderDateTime: string) {
    const actualDateTime = new Date();
    const parsedOrderDateTime = new Date(orderDateTime);
    if (
      differenceInMinutes(
        new Date(actualDateTime),
        new Date(parsedOrderDateTime)
      ) < this.metrcOrderLatency
    ) {
      try {
        await this.$modals.load(
          ConfirmModalComponent,
          {
            size: "normal"
          },
          {
            modalData: {
              body: i18n.t("alert_time_of_sale").toString()
            }
          }
        );
        return true;
      } catch (error) {
        return false;
      }
    }
    return true;
  }

  public async syncMetrcStatus(order: Order, type: "sales" | "refunds") {
    if (await this.validateTime(order.created_at!)) {
      this.$modals
        .load(LoadingWindowComponent, {
          closable: false,
          size: "fit",
          positionY: "center",
          positionX: "center",
          backdrop: true
        })
        .catch(() => {
          // nothing to do
        });
      const serverResponse = await traceabilityService.syncMetrcStatus(
        order.id!,
        type
      );
      if (serverResponse && serverResponse.errors) {
        EventBus.$emit("mtrcLoadingEvent", {
          show: true,
          errorList: [{ message: serverResponse.errors[0].message }]
        });
      }
      if (serverResponse.status === "success") {
        EventBus.$emit("mtrcLoadingEvent", {
          show: false
        });
        if (type === "sales") {
          this.paginationOrders.currentPage = 1;
          this.getOrders(QUERY_DEFAULT);
        } else if (type === "refunds") {
          this.paginationRefunds.currentPage = 1;
          this.getRefunds(QUERY_DEFAULT);
        }
      }
    }
  }

  public metrcDetails(order: Order, isRefund = false) {
    metrcSalesActionService.action(order, isRefund);
  }

  public addProducts(data: {
    operationUid: string;
    products: ProductVariant[];
  }) {
    if (!Array.isArray(data.products)) {
      return;
    }

    const index = this.refunds.findIndex(
      r => r.operation_uid === data.operationUid
    );

    this.refunds[index] = {
      ...this.refunds[index],
      products: data.products
    };
  }

  public addRefunItemsinformation(data: {
    operationUid: string;
    items: RefundHistoryItem[];
  }) {
    const index = this.refunds.findIndex(
      r => r.operation_uid === data.operationUid
    );
    this.refunds[index] = {
      ...this.refunds[index],
      items: this.refunds[index].items.map(rItem => {
        const iteminformation = data.items.find(
          item => item.order_item_id === rItem.order_item_id
        );
        return {
          ...rItem,
          taxes_breakdown: iteminformation!.taxes_breakdown,
          discounts_breakdown: iteminformation!.discounts_breakdown
        };
      })
    };
  }

  public onSort(data: {
    header: TableHeader;
    type: "sales" | "refunds";
    filters: FilterModel;
  }) {
    const desc: string = data.header.descending ? "-" : "";
    const sort = desc + data.header.value;
    if (data.type === "refunds") {
      this.getRefunds({
        filters: { ...data.filters, sort },
        pagination: {
          page: this.paginationRefunds.currentPage,
          per_page: this.paginationRefunds.itemsPerPage
        }
      });
    } else {
      this.getOrders({
        filters: { ...data.filters, sort },
        pagination: {
          page: this.paginationOrders.currentPage,
          per_page: this.paginationOrders.itemsPerPage
        }
      });
    }
  }

  public async getPMPconfiguration() {
    const config = await pmpIntegrationService.getCurrentPmpFtpConfig([
      +this.currentLocation.id!
    ]);
    if (config.length) {
      if (config[0].status) {
        this.pmpIntegration = true;
      } else {
        this.pmpIntegration = false;
      }
    } else {
      this.pmpIntegration = false;
    }
  }

  protected redirect() {
    this.$router.push("/metrc-tools/retail-sales-reconciliation");
  }

  protected async created() {
    await this.getPMPconfiguration();
  }

  protected mounted() {
    this.currentTab = this.$route.name!;
    this.onChangeTab();
    this.setPageNav({
      title: "retail_sales_history",
      rightActions: {
        generalActions: () => [
          {
            action: this.redirect,
            icon: "fal fa-ticket",
            class: "iconButtonClassRotate135",
            visibleCondition: () => {
              return (
                this.hasPermission(policyList.viewModifySalesReconciliation) &&
                this.hasMetrc
              );
            },
            vuetifyProps: () => ({
              fab: true,
              small: true,
              loading: this.loadingOrder && this.loadingRefund
            })
          }
        ]
      }
    });

    try {
      this.setCurrentTill();
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  private setTabRoute() {
    this.$router.push({
      name: this.currentTab === "sales" ? "sales" : "refund-history"
    });
  }
}
