import { Customer } from "@/interfaces/customer";
import { OrderItem } from "@/interfaces/order";
import { User } from "@/interfaces/user";
import { i18n } from "@/plugins/i18n";
import { integrationService } from "@/services/integration.service";
import {
  FormFieldTypes,
  HelixDatePickerTypes,
  TableFieldType,
  TableHeader
} from "helix-vue-components";

export const getHeaders = (setPricesItems: () => void): TableHeader[] => {
  return [
    {
      value: "batch_barcode_uid",
      label: i18n.t("batch_id").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (field: OrderItem) => {
        return !field.available ? "refunded" : "";
      }
    },
    {
      value: "product_info.name",
      label: i18n.t("refund_retail_sales.product").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (field: OrderItem) => {
        return !field.available ? "refunded" : "";
      }
    },
    {
      value: "quantity/j/product_info.product_unit",
      label: i18n.t("refund_retail_sales.quantity_sold").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (field: OrderItem) => {
        return !field.available ? "refunded" : "";
      }
    },
    {
      value: "subtotal",
      label: i18n.t("refund_retail_sales.subtotal").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (field: OrderItem) => {
        return !field.available ? "refunded" : "";
      }
    },
    {
      value: "tax_amount",
      label: i18n.t("refund_retail_sales.tax").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (field: OrderItem) => {
        return !field.available ? "refunded" : "";
      }
    },

    {
      value: "price_final",
      label: i18n.t("refund_retail_sales.line_item_total").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (field: OrderItem) => {
        return !field.available ? "refunded" : "";
      }
    },
    {
      value: "refund/j/product_info.product_unit",
      label: i18n.t("refund_retail_sales.quantity_refunded").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (field: OrderItem) => {
        return !field.available ? "refunded" : "";
      }
    },
    {
      value: "available/j/product_info.product_unit",
      label: i18n.t("refund_retail_sales.available_for_refound").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (field: OrderItem) => {
        return !field.available ? "refunded" : "";
      }
    },
    {
      value: "quantityToRefund",
      label: i18n.t("refund_retail_sales.refund_quantity").toString(),
      fieldComponent: TableFieldType.counter,
      vuetifyProps: (item: OrderItem) => ({
        max: item.available,
        min: !item.available ? 0 : 1,
        disabled: !item.available
      }),
      options: {
        isInteger: (item: OrderItem) => item.product_info!.product_unit === "u",
        onChange: (item: OrderItem) => {
          item.quantityToRefund =
            item.quantityToRefund! >= item.available!
              ? item.available
              : item.quantityToRefund!;
          item.quantityToRefund =
            item.quantityToRefund! <= 0
              ? +item.available!
              : item.quantityToRefund!;
          setTimeout(() => {
            setPricesItems();
          }, 300);
        }
      },
      conditionalClassFn: (field: OrderItem) => {
        return !field.available
          ? "refunded number-input_hidden_arrows"
          : "number-input_hidden_arrows";
      }
    },
    {
      value: "unitPriceForRefund",
      label: i18n.t("refund_retail_sales.refund_price_unit").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (field: OrderItem) => {
        return !field.available ? "refunded" : "";
      }
    },
    {
      value: "totalPricesRefundItems",
      label: i18n.t("refund_retail_sales.price_preview").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (field: OrderItem) => {
        return !field.available ? "refunded" : "";
      }
    }
  ];
};

export const filterFormConfig = (
  customersCallBack: (
    d: { newValue: string }
  ) => Promise<Customer[] | undefined>,
  usersCallback: (d: { newValue: string }) => Promise<User[] | undefined>,
  showMetrc: boolean,
  showPmp: boolean
) => [
  {
    component: FormFieldTypes.datepicker,
    label: i18n.t("start_date"),
    name: "startDate",
    options: {
      placeholder: i18n.t("start_date"),
      pickerOptions: {
        type: HelixDatePickerTypes.date
      },
      row: 1,
      flex: 2
    }
  },
  {
    component: FormFieldTypes.datepicker,
    label: i18n.t("end_date"),
    name: "endDate",
    options: {
      placeholder: i18n.t("end_date"),
      pickerOptions: {
        type: HelixDatePickerTypes.date
      },
      row: 1,
      flex: 2
    }
  },
  {
    component: FormFieldTypes.asyncAutocomplete,
    label: i18n.t("customer.customer"),
    name: "customer",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 2,
      associationValue: "customer_id",
      associationText: (arg: Customer) => `${arg.first_name} ${arg.last_name}`,
      selectOptions: customersCallBack,
      vuetifyProps: {
        "item-value": "customer_id",
        "no-data-text": i18n.t("no_search_results")
      }
    }
  },
  {
    component: FormFieldTypes.asyncAutocomplete,
    label: i18n.t("user"),
    name: "users",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 2,
      associationValue: "id",
      associationText: "name",
      selectOptions: usersCallback,
      vuetifyProps: {
        "no-data-text": i18n.t("no_search_results"),
        "item-value": "id",
        "deletable-chips": true,
        "cache-items": true,
        multiple: true,
        chips: true
      },
      searchValueLength: 0
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("transaction_id"),
    name: "transactionId",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 2
    }
  },
  {
    label: i18n.t("reports_group.filters.transaction_type"),
    name: "source",
    component: FormFieldTypes.autocomplete,
    options: {
      placeholder: i18n.t("type_to_search"),
      associationText: "text",
      associationValue: "value",
      selectOptions: () => [
        { value: "CALL_IN", text: "Call-in pre-order" },
        { value: "IN_STORE", text: "In-Store fulfillment order" },
        { value: "ONLINE", text: "Online pre-order" },
        { value: "ORDER", text: "Order" }
      ],
      row: 1,
      flex: 2
    }
  },
  {
    label: i18n.t("reports_group.filters.source"),
    name: "vendor",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      disableCondition: (model: { source?: string }) =>
        !(model.source === "ONLINE"),
      placeholder: i18n.t("type_to_search"),
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        integrationService.searchApps(reference.search),
      row: 1,
      flex: 2,
      vuetifyProps: {
        "no-data-text": i18n.t("no_search_results"),
        "item-value": "id",
        "deletable-chips": true,
        "cache-items": true,
        multiple: true,
        chips: true
      }
    }
  },
  {
    component: FormFieldTypes.autocomplete,
    label: i18n.t("trc_status"),
    name: "trcStatus",
    options: {
      associationText: "text",
      associationValue: "value",
      visibleCondition: () => showMetrc,
      selectOptions: () => [
        {
          value: "SUCCESSFUL",
          text: i18n.t("filter_reports.successful")
        },
        {
          value: "NOT_UPLOADED",
          text: i18n.t("filter_reports.not_uploaded")
        },
        {
          value: "NOT_NEEDED",
          text: i18n.t("filter_reports.not_needed")
        }
      ],
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 2
    }
  },
  {
    component: FormFieldTypes.autocomplete,
    label: i18n.t("pmp.status"),
    name: "pmpStatus",
    options: {
      associationText: "text",
      associationValue: "value",
      visibleCondition: () => showPmp,
      selectOptions: () => [
        {
          value: "UPLOADED",
          text: i18n.t("pmp.uploaded")
        },
        {
          value: "NOT_UPLOADED",
          text: i18n.t("pmp.not_uploaded")
        },
        {
          value: "NON_CANNABIS",
          text: i18n.t("pmp.non_cannabis")
        }
      ],
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 2
    }
  }
];
