import { Customer } from "@/interfaces/customer";
import { User } from "@/interfaces/user";
import { i18n } from "@/plugins/i18n";
import { FormField, FormFieldTypes } from "helix-vue-components";

export const salesHistoryFormConfig: (
  text: (item: any) => string,
  customersCallback: (
    d: { newValue: string }
  ) => Promise<Customer[] | undefined>,
  usersCallback: (d: { newValue: string }) => Promise<User[] | undefined>
) => FormField[] = (
  text: (item: any) => string,
  customersCallback: (
    d: { newValue: string }
  ) => Promise<Customer[] | undefined>,
  usersCallback: (d: { newValue: string }) => Promise<User[] | undefined>
) => [
  {
    component: FormFieldTypes.datepicker,
    label: i18n.t("starting_time"),
    name: "startingTime",
    options: {
      pickerOptions: {
        id: "input_starting_time"
      },
      placeholder: i18n.t("starting_time"),
      row: 1,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.datepicker,
    label: i18n.t("ending_time"),
    name: "endingTime",
    options: {
      pickerOptions: {
        id: "input_ending_time"
      },
      placeholder: i18n.t("ending_time"),
      row: 1,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.asyncAutocomplete,
    label: i18n.t("customer.customer"),
    name: "customerSelected",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 3,
      associationValue: "id",
      associationText: text,
      selectOptions: customersCallback,
      vuetifyProps: {
        "no-data-text": i18n.t("no_search_results"),
        "return-object": true,
        "item-value": "id"
      }
    }
  },
  {
    component: FormFieldTypes.asyncAutocomplete,
    label: i18n.t("user"),
    name: "users",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 3,
      associationValue: "id",
      associationText: "name",
      selectOptions: usersCallback,
      vuetifyProps: {
        "no-data-text": i18n.t("no_search_results"),
        "return-object": true,
        "item-value": "id",
        "deletable-chips": true,
        "cache-items": true,
        multiple: true,
        chips: true
      }
    }
  }
];

export const salesHistoryFieldsFunction = (
  changingField: string
): Array<{ label: string; value: string }> => {
  let field: any[] = [];
  switch (changingField) {
    case "SECONDARY_ID":
      field = ["inventory_settings_view.secondary_id", "tracking_id"];
      break;
    case "NDC_NUMBER":
      field = ["inventory_settings_view.ndc_number", "product_info.ndc_number"];
      break;
    default:
      // NONE
      field = ["", ""];
      break;
  }
  const fieldsToReturn = [
    {
      label: i18n.t("batch_id").toString(),
      value: "batch_barcode_uid"
    },
    {
      label: i18n.t(field[0]).toString(),
      value: field[1]
    },
    {
      label: i18n.t("product").toString(),
      value: "product_info.name"
    },
    {
      label: i18n.t("quantity").toString(),
      value: "quantityFormated"
    },
    {
      label: i18n.t("price").toString(),
      value: "price_raw"
    },
    {
      label: i18n.t("line_item_discounts").toString(),
      value: "line_item_discount_without_taxes"
    },
    {
      label: i18n.t("price_after_discounts").toString(),
      value: "subtotal"
    },
    {
      label: i18n.t("line_item_tax").toString(),
      value: "tax_amount"
    },
    {
      label: i18n.t("refund_retail_sales.line_item_total").toString(),
      value: "price_final"
    },
    {
      label: i18n.t("rx_number").toString(),
      value: "prescription_no"
    }
  ];

  if (changingField === "NONE") {
    fieldsToReturn.splice(1, 1);
  }

  return fieldsToReturn;
};
