import NextTagComponent from "@/components/sharedComponents/nextTag/NextTag.component";
import { OrderItem } from "@/interfaces/order";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./CreatenewbatchRefund.template.vue";

@Component({
  mixins: [Template],
  components: {
    NextTagComponent
  }
})
export default class CreatenewbatchRefund extends Vue {
  @Prop({ required: true })
  public itemsSelected!: OrderItem[];

  @Getter("metrcEnabled", { namespace: "AuthModule" })
  public hasMetrc!: boolean;

  public cloneItemsSelected: OrderItem[] = [];
  public cannabisItems: OrderItem[] = [];
  public secondaryID: string | null = null;

  public previousStep() {
    this.$emit("reject");
  }

  public async setItemsToReturn() {
    if (await this.$validator.validate()) {
      this.cloneItemsSelected = this.cloneItemsSelected.map(item => {
        const cannabisItem = this.cannabisItems.find(
          cItem => cItem.id === item.id
        );
        return cannabisItem ? cannabisItem : item;
      });
      this.$emit("resolve", this.cloneItemsSelected);
    }
  }

  public skipStep() {
    this.$emit("skipStep");
  }

  public async created() {
    this.cloneItemsSelected = cloneDeep(this.itemsSelected);
    this.cannabisItems = this.cloneItemsSelected.filter(
      item => item.product_info!.marijuana === 1
    );
  }
}
