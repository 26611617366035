var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"salesHistory my-0 py-0"},[_c('v-layout',{attrs:{"px-3":"","pt-4":""}},[_c('v-flex',[_c('DynamicFormComponent',{ref:"filter-form",attrs:{"fieldsConfig":_vm.fieldsConfig,"model":_vm.currentFilter},on:{"change":_vm.onSetFilters}})],1)],1),_vm._v(" "),_c('table-component',{attrs:{"data":_vm.parsedOrders,"headers":_vm.headers,"loading":_vm.loading,"expand":true,"rowActions":_vm.rowActions,"colors":_vm.tableColors,"pagination":_vm.pagination},on:{"changePagination":_vm.changePagination,"filterParams":_vm.sort},scopedSlots:_vm._u([{key:"pmp_status",fn:function(column){return [(column.data.pmp_status === 'WARNING')?_c('v-tooltip',{attrs:{"right":"","max-width":"300"}},[_c('v-icon',{staticClass:"ml-2",attrs:{"slot":"activator","color":"warning","size":"25"},slot:"activator"},[_vm._v("fal fa-exclamation-triangle")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("pmp.pmp_upload_warning_message")))])],1):(column.data.pmp_status === 'ERROR')?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("pmp.pmp_upload_error")))]),_vm._v(" "),_c('v-tooltip',{attrs:{"right":"","max-width":"300"}},[_c('v-icon',{staticClass:"ml-2",attrs:{"slot":"activator","color":"error","small":""},slot:"activator"},[_vm._v("fas fa-times")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("pmp.pmp_upload_error_message")))])],1)],1):(column.data.pmp_status === 'UPLOADED')?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("pmp.uploaded")))]),_vm._v(" "),_c('v-icon',{staticClass:"ml-2",attrs:{"slot":"activator","color":"success","size":"25"},slot:"activator"},[_vm._v("fal fa-arrow-alt-up")])],1):_c('span',[_vm._v(_vm._s(column.data.pmp_status))])]}},{key:"source_name",fn:function(data){return [_vm._v("\n      "+_vm._s(data.data.pre_order
          ? data.data.pre_order.source === "ONLINE"
            ? data.data.pre_order.oauth_app &&
              data.data.pre_order.oauth_app.length
              ? data.data.pre_order.oauth_app[0].name
              : "--"
            : "--"
          : "--")+"\n    ")]}},{key:"default",fn:function(row){return _c('div',{},[_c('TableProductsComponent',{attrs:{"list":row.data.order_items,"orderData":row.data,"fieldsToShow":_vm.fieldsToShow,"loading":false},on:{"refreshOrder":_vm.onLoadOrders}})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }