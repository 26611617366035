import { AxiosResponse } from "axios";
import HttpService from "./http.service";

interface App {
  id: number;
  name: string;
}
/**
 * integrations service
 * @override
 */
class IntegrationService extends HttpService {
  protected uri: string = `${process.env.VUE_APP_CORE_URL}`;

  public async searchApps(value: string): Promise<App[]> {
    this.query["q[name_contains]"] = value;
    this.uri = `${process.env.VUE_APP_CORE_URL}/auth/apps`;
    const response: AxiosResponse = await super.get(this.query);
    return response.data.data;
  }
}

export const integrationService = new IntegrationService();
