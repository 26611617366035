import { barcodeDefault } from "@/components/template/barcode/default";
import { BatchDetail, InventoryBatch } from "@/interfaces/batch";
import { ProductPricing } from "@/interfaces/product";
import { FieldDisplay, TemplateConfig } from "@/interfaces/templateEditor";
import { batchLevelService } from "@/services/batchLevel.service";
import { productService } from "@/services/product.service";
import { templatesService } from "@/services/templates.service";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import forIn from "lodash/forIn";
import kebabCase from "lodash/kebabCase";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import VueBarcode from "vue-barcode";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./barcodeList.template.vue";

@Component({
  mixins: [Template],
  components: {
    VueBarcode
  }
})
export default class BarcodeListComponent extends Vue {
  @Prop({ required: true })
  public batches!: InventoryBatch[];
  // This flag getPriceByBatches is used to determine which API call we need to make to get the prices
  @Prop()
  public getPriceByBatches!: boolean;

  public productPrices: {
    [productSKU: string]: ProductPricing | null;
  } = {};

  public templateConfig: TemplateConfig = barcodeDefault;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;

  public getFieldStyle(fieldDisplay: any) {
    if (fieldDisplay && fieldDisplay.style) {
      const styles: string[] = [];
      forIn(fieldDisplay.style, (val, k) => {
        if (!!+val) {
          val = val + "px";
        }
        styles.push(`${kebabCase(k)}: ${val}`);
      });
      return styles.join("; ");
    }
  }

  public getProductName(batch: InventoryBatch) {
    if (batch.fatherItem) {
      return batch.fatherItem.name;
    } else if (batch.name) {
      return batch.name;
    }
    return batch.product ? batch.product.name : batch.product_variant.name;
  }

  public getBatchId(batch: InventoryBatch) {
    const marijuana = batch.product
      ? batch.product.marijuana
      : batch.product_variant.marijuana;
    if (batch.new_batch_print) {
      if (batch.biotrack_traceability_id) {
        return batch.biotrack_traceability_id;
      }
      return batch.batch_uid;
    } else {
      if (
        this.bioTrackTraceEnabled &&
        marijuana === 1 &&
        batch.biotrack_traceability_id
      ) {
        return batch.biotrack_traceability_id;
      }
      return batch.batch_uid;
    }
  }

  public getProductPrice(batch: InventoryBatch): string {
    let sku = batch.product!.sku! || batch.product_variant.sku;
    if (this.getPriceByBatches) {
      sku = batch.batch_sku!;
      this.productPrices[sku!]!.rules = this.productPrices[sku!]!.pricing!;
    }
    const prices = this.productPrices[sku!];
    const currentRule =
      (batch.product &&
        (
          productService.getLocationPricingRule({
            pricing: (prices && prices.rules) || []
          }) || {}
        ).price_breaks!) ||
      [];
    if (currentRule.length) {
      const usableWeight =
        batch.product &&
        batch.product.usable_weight_value &&
        batch.product.price_settings!.price_point_by_usable_weight
          ? batch.product.usable_weight_value
          : 1;
      const pricePoint =
        maxBy(currentRule, p => p.quantity <= usableWeight && p.quantity) ||
        minBy(currentRule, "quantity");
      return (
        (
          (pricePoint!.post_tax! / pricePoint!.quantity) *
          usableWeight
        ).toString() || "--"
      );
    }
    return "--";
  }

  public getStrainName(batch: InventoryBatch) {
    if (batch.fatherItem) {
      return batch.fatherItem.strain ? batch.fatherItem.strain.name : null;
    } else if (batch.strain) {
      return batch.strain;
    }
    return batch.product_variant.strain
      ? batch.product_variant.strain.name
      : null;
  }

  public formatDate(date: string) {
    return fnsFormatDate(
      new Date(date),
      FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
    );
  }

  public getBarcodeOptions(display: FieldDisplay) {
    return {
      textAlign: display.style.textAlign || "center",
      height: display.style.lineHeight || 32,
      width: 1,
      marginTop: display.style.marginTop || 8,
      marginBottom: display.style.marginBottom || 8,
      marginLeft: 0,
      marginRight: 0,
      displayValue: false,
      format: "CODE128"
    };
  }

  protected async setProductsPricing() {
    this.productPrices = await this.batches.reduce(
      async (
        prev: Promise<{ [productSKU: string]: ProductPricing | null }>,
        batch
      ) => {
        if (this.getPriceByBatches) {
          const acc = await prev;
          const productSKU: string | undefined = batch.batch_sku;
          if (!acc[productSKU!]) {
            acc[productSKU!] = await batchLevelService.getBatchPrices(
              productSKU!
            );
          }
          return acc;
        } else {
          const acc = await prev;
          const productSKU =
            batch.product_sku ||
            batch.product!.sku! ||
            batch.product_variant.sku;
          if (!acc[productSKU]) {
            acc[productSKU] = await productService.getProductPricing(
              productSKU
            );
          }
          return acc;
        }
      },
      Promise.resolve({})
    );
  }

  protected async created() {
    const templateConfig = await templatesService.getDefault("BARCODE");
    if (templateConfig) {
      this.templateConfig = templateConfig.config as TemplateConfig;
      if (this.templateConfig.price.visible) {
        await this.setProductsPricing();
      }
    }
    this.$nextTick(() => this.$emit("readyToPrint", "barcode"));
  }
}
