import { Order, Refund, RefundHistory } from "@/interfaces/order";
import { Payment } from "@/interfaces/payment";
import { PaymentMethod } from "@/interfaces/retailSettings";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import {
  OrderPayment,
  paymentMethodsService
} from "@/services/paymentMethods.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./ModifyPayment.template.vue";

@Component({
  mixins: [Template]
})
export class ModifyPaymentComponent extends Vue {
  @Getter("paymentMethods", { namespace: "AuthModule" })
  public paymentMethods!: PaymentMethod[];

  @Prop({ required: true })
  public item!: Order;
  @Prop({ default: "" })
  public type!: string;
  @Prop()
  public currentRefund!: Refund;
  public refundItemId!: number;
  public isLoading = false;
  public currentTab: string = "sales";
  public partialRefundModel: Payment[] = [];

  public rules = {
    amount: [(val: any) => val > 0 || this.invalidValueText],
    method: [
      (val: { name: string; id: number }) =>
        (!!val.name && !!val.id) || this.invalidValueText
    ]
  };

  public invalidValueText = "Invalid value";

  public get paymentMethodsFiltered() {
    return this.paymentMethods.reduce(
      (acc: any[], payment) =>
        payment.enabled
          ? [...acc, { name: payment!.name, id: payment.id }]
          : acc,
      []
    );
  }

  public get formIsValid() {
    return this.paymentModel.reduce((acc: boolean, model: Payment) => {
      return !acc
        ? false
        : !!model.amount && model.amount > 0 && !!model.payment_method.name;
    }, true);
  }

  public paymentModel = (this.item.order_payments as Payment[]).map(
    payment => ({
      amount: payment.amount,
      id: payment.id,
      payment_method: {
        name: payment && payment.payment_method.name,
        id: payment && payment.payment_method.id
      }
    })
  ) as Payment[];

  // New filter function to filter repeated values during partial refunds
  public filteredPaymentModel() {
    this.partialRefundModel = this.paymentModel.filter(model => {
      return this.item.order_payment_refunds!.find(
        element =>
          element.order_refund_id === this.refundItemId &&
          element.id === model.id
      );
    });
  }

  public paymentsModel() {
    // Function returning separate payment methods
    if (this.currentTab === "sales") {
      return this.paymentModel;
    } else {
      return this.partialRefundModel;
    }
  }

  public close() {
    this.$emit("reject");
  }

  public newMethod() {
    const newPayment = {
      amount: null,
      // Generate a random id number in for transition-group rendering
      render_id: Date.now(),
      payment_method: {
        name: null,
        id: undefined
      }
    };
    // Updating payment models as per partial & full-refund
    if (this.currentTab === "sales") {
      this.paymentModel = [...this.paymentModel, newPayment];
    } else {
      this.partialRefundModel = [...this.partialRefundModel, newPayment];
    }
  }

  public remove(index: number) {
    // Updating payment models as per partial & full-refund
    if (this.currentTab === "sales") {
      this.paymentModel.splice(index, 1);
    } else {
      this.partialRefundModel.splice(index, 1);
    }
  }

  public totalAmounts(payments: Payment[]): number {
    // Current will always be defined because otherwise the submit (apply) button would be disabled
    return payments.reduce((acc, curr) => acc + +curr.amount!, 0);
  }

  public mounted() {
    EventBus.$emit("unSelectedModalActive");
  }

  public beforeMount() {
    this.currentTab = this.$route.name!;
    this.refundItemId = this.currentRefund!.id;
    this.filteredPaymentModel();
  }

  public async submit() {
    this.isLoading = true;
    if (this.currentTab !== "sales") {
      this.paymentModel = this.partialRefundModel;
    }
    const currentTotal = this.totalAmounts(this.paymentModel);
    if (
      this.item.total &&
      parseInt(`${this.item.total * 100}`, 0) / 100 > currentTotal
    ) {
      EventBus.$emit("notify", {
        text: "modify_payment.amount_error",
        color: "error"
      });
      this.isLoading = false;
    } else if (
      this.item.total &&
      parseInt(`${this.item.total * 100}`, 0) / 100 < currentTotal
    ) {
      messagesService.renderErrorMessage("modify_payment.less_amount_error");
      this.isLoading = false;
    } else {
      try {
        const response = await paymentMethodsService.modifyPayment(
          this.item.id!,
          {
            order_payments: this.paymentModel.map(
              pay =>
                ({
                  payment_method_id: pay.payment_method.id,
                  amount: pay.amount
                } as OrderPayment)
            ),
            orderRefundIds:
              this.type === "refund" || this.type === "partial_refund"
                ? [this.refundItemId]
                : []
          },
          this.type,
          this.type === "refund" || this.type === "partial_refund"
            ? "refund"
            : "sale"
        );

        if (response) {
          this.type === "refund" || "partial_refund"
            ? EventBus.$emit("paymentChangedRefund")
            : EventBus.$emit("paymentChanged");
          this.$emit("resolve");
        }

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        messagesService.renderErrorMessage(e);
      }
    }
  }
}
