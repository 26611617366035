import { ModalConfirm } from "@/interfaces/notification";
import { Order } from "@/interfaces/order";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./uploadPMP.template.vue";

@Component({
  mixins: [Template]
})
export class UploadPmpPopupComponent extends Vue {
  @Prop({ required: true })
  public modalData!: ModalConfirm;
  @Prop({ required: true })
  public item!: Order;
  public isLoading = false;

  public close() {
    this.$emit("reject");
  }
}
