import { TemplateConfig } from "@/interfaces/templateEditor";

export const barcodeDefault: TemplateConfig = {
  productName: {
    text: "template.barcode.product_name",
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left",
        marginTop: "8",
        marginBottom: "8"
      }
    },
    visible: true
  },
  strain: {
    text: "template.barcode.strain",
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left",
        marginTop: "8",
        marginBottom: "8"
      }
    },
    visible: true
  },
  batchType: {
    text: "template.barcode.batch_type",
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left",
        marginTop: "8",
        marginBottom: "8"
      }
    },
    visible: false
  },
  price: {
    text: "template.barcode.price",
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left",
        marginTop: "8",
        marginBottom: "8"
      }
    },
    visible: false
  },
  date: {
    text: "template.barcode.date",
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left",
        marginTop: "8",
        marginBottom: "8"
      }
    },
    visible: false
  },
  secondaryId: {
    text: "template.barcode.secondary_id",
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left",
        marginTop: "8",
        marginBottom: "8"
      }
    },
    visible: false
  },
  barcode: {
    text: "template.barcode.barcode",
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "32",
        lineHeight: "32",
        alignSelf: "center",
        textAlign: "center",
        marginTop: "8",
        marginBottom: "8"
      }
    },
    visible: true
  },
  batchId: {
    text: "template.barcode.batch_id",
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "center",
        textAlign: "center",
        marginTop: "8",
        marginBottom: "8"
      }
    },
    visible: true
  }
};
