import { PmpConfig } from "@/interfaces/pmpConfig";
import HttpService from "./http.service";

class PmpIntegrationService extends HttpService {
  public async getCurrentPmpFtpConfig(
    location?: number[]
  ): Promise<PmpConfig[]> {
    try {
      this.uri = process.env.VUE_APP_BASE_URL + "/integrations/pmp_ftp_configs";
      const response = await super.get({ location_id: location });
      return response.data.data;
    } catch (error) {
      return [];
    }
  }
}

export const pmpIntegrationService: PmpIntegrationService = new PmpIntegrationService();
