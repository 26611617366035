import RefundModal from "@/components/retail/pos/pointOfSale/posCustomerList/refund/refundModal/RefundModal.component";
import { policyList } from "@/enums/permissions";
import {
  Order,
  OrderItem,
  Refund,
  RefundActionCallback
} from "@/interfaces/order";
import { store } from "@/internal";
import {
  userHasNotOpenTill,
  userWithoutOpenFillMessage
} from "@/metadata/money";
import { i18n } from "@/plugins/i18n";
import { TableAction } from "helix-vue-components";
import { Vue } from "vue-property-decorator";

class RefundActionService extends Vue {
  public getRowActions(
    actionCallbacks: RefundActionCallback,
    showPmp: boolean
  ): TableAction[] {
    return [
      {
        icon: "fal fa-ellipsis-v",
        id: "btn_retail_options",
        modalActions: {
          modalNumber: 2,
          modalButtons: [
            {
              icon: "fas fa-usd-circle",
              label: i18n.t("void_refund").toString(),
              class: "primary-base",
              tooltip: userWithoutOpenFillMessage,
              visibleCondition: (arg: Refund) => {
                const status = arg.items.map(
                  item =>
                    item.order_items!.order!.status === "PARTIALLY_REFUND" ||
                    "REFUND"
                );
                const hasPerm = store.getters[
                  "PermissionsModule/hasPermission"
                ](policyList.voidSale);
                // Using 0 index because we only need one value from the array for the condition
                return !!(hasPerm && status[0]);
              },
              disableCondition: userHasNotOpenTill,
              action: actionCallbacks.voidRefund
            },
            {
              icon: "fal fa-usd-circle",
              label: i18n.t("modify_payment_lbl").toString(),
              class: "primary-base",
              tooltip: userWithoutOpenFillMessage,
              disableCondition: userHasNotOpenTill,
              visibleCondition: (arg: Refund) => {
                const status = arg.items.map(
                  item =>
                    item.order_items!.order!.status === "PARTIALLY_REFUND" ||
                    "REFUND"
                );
                // Using 0 index because we only need one value from the array for the condition
                return !!status[0];
              },
              action: actionCallbacks.payment
            },
            {
              icon: "fal fa-print",
              label: i18n.t("reprint_receipts").toString(),
              class: "primary-base",
              action: actionCallbacks.reprintReceipts
            },
            {
              icon: "fal fa-barcode-alt",
              label: i18n.t("print_barcodes").toString(),
              class: "primary-base",
              action: actionCallbacks.printBarcodes
            },
            {
              icon: "fal fa-file-search",
              label: i18n.t("metrc_details.action_button").toString(),
              class: "primary-base",
              visibleCondition: (order: Refund) => {
                const hasPerm = store.getters[
                  "PermissionsModule/hasPermission"
                ](policyList.uploadSalesToMetrcViewMetrcSalesDetails);
                return !!(
                  hasPerm &&
                  actionCallbacks.hasMetrc() &&
                  order.trc_receipt_id &&
                  order.trc_status === "SUCCESSFUL" &&
                  order.items &&
                  order.items.length &&
                  order.items.find(item => !!item.marijuana)
                );
              },
              action: actionCallbacks.metrcDetail
            },
            {
              icon: "fal fa-upload",
              label: i18n.t("upload_to_metrc").toString(),
              class: "primary-base",
              visibleCondition: (order: Refund) => {
                const hasPerm = store.getters[
                  "PermissionsModule/hasPermission"
                ](policyList.uploadSalesToMetrcViewMetrcSalesDetails);
                return !!(
                  hasPerm &&
                  actionCallbacks.hasMetrc() &&
                  order.trc_status === "NOT_UPLOADED" &&
                  order.items &&
                  order.items.length &&
                  order.items.find(item => !!item.marijuana)
                );
              },
              action: actionCallbacks.syncMetrcStatus
            },
            {
              icon: "fal fa-upload",
              label: i18n.t("pmp.upload_action").toString(),
              class: "primary-base",
              visibleCondition: (order: Order) => {
                return (
                  showPmp &&
                  order.pmp_status !== "UPLOADED" &&
                  order.pmp_status !== "Non-Cannabis"
                );
              },
              action: actionCallbacks.uploadPmp
            }
          ]
        }
      }
    ];
  }

  public async modalRefund(order: Order) {
    try {
      const result: {
        data: object;
        printWasAllowed: boolean;
      } = await this.$modals.load(
        RefundModal,
        {
          size: "full_width",
          positionX: "center"
        },
        {
          currentCustomer: order.customer,
          order: {
            ...order,
            order_items: order.order_items!.map(item => ({
              ...item,
              price_final: Math.trunc(item.price_final! * 100) / 100
            }))
          }
        }
      );

      this.$emit("loadOrders");
    } catch (error) {
      // Nothig to do
    }
  }
}
export const refundActionService = new RefundActionService();
