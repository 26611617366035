import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import BarcodeListComponent from "@/components/sharedComponents/print/barcodeList/barcodeList.component";
import { Customer } from "@/interfaces/customer";
import {
  Order,
  OrderItem,
  RefundHistory,
  RefundHistoryItem,
  RefundInformation
} from "@/interfaces/order";
import { PaymentMethod, TypePaymentMethod } from "@/interfaces/retailSettings";
import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { moneyService } from "@/services/money.service";
import { orderService } from "@/services/order.service";
import { productService } from "@/services/product.service";
import { getParentSKU } from "@/utils/batch-actions.utils";
import pick from "lodash/pick";
import sumBy from "lodash/sumBy";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import PrintReceiptRefundComponent from "../printReceiptRefund/PrintReceiptRefund.component";
import Template from "./RefundDecideModal.template.vue";
const TYPE_REMOVE_CASH = {
  type: "REMOVE",
  sub_type: "ITEM_REFUND",
  description: ""
};

const trunc = (num: number, digits = 2) => {
  return Math.trunc(num * Math.pow(10, digits)) / Math.pow(10, digits);
};

@Component({
  mixins: [Template],
  components: {
    PrintReceiptRefundComponent
  }
})
export default class RefundDecideModal extends Vue {
  public refundAmount = 0;
  @Getter("paymentMethods", { namespace: "AuthModule" })
  public paymentMethods!: PaymentMethod[];
  @Prop({ required: true })
  public hasMetrc!: boolean;
  @Prop({ required: true })
  public currentCustomer!: Customer;
  @Prop({ required: true })
  public order!: Order;
  @Getter("user", { namespace: "AuthModule" })
  public user!: User;
  @Prop({ required: true })
  public itemsSelected!: OrderItem[];
  @Prop({ required: true })
  public infoItemsSelected!: RefundInformation;
  @Prop({ required: true })
  public hasCannabisProduct!: boolean;
  public loading = false;
  public typeRefund: boolean = false;
  public isLoading = false;
  public refund: RefundHistory | null = null;
  public invalidValueTextAmount = "refund.amount_match";
  public invalidValueTextMethod = "refund.method_not_selected";
  public refundTypeOptions: Array<{ value: string; text: string }> = [];
  public rules!: {
    amount: Array<(val: number) => true | string>;
    method: Array<(val: PaymentMethod) => true | string>;
  };
  public date: string = "";
  public breakdown: object = {};
  public paymentMethodMaxValueMap: Record<string, number> = {
    CHECK: 0,
    CREDIT_CARD: 0,
    DEBIT_CARD: 0
  };
  // This flag printOnlyOnce is used as flag to know how much time we are priting . we need to only print barcode once
  public printOnlyOnce = false;

  public paymentModel: PaymentMethod[] = [];
  public get paymentMethodsFiltered() {
    return this.paymentMethods.filter(
      method =>
        method.type === TypePaymentMethod.CASH ||
        method.type === TypePaymentMethod.STORE_CREDIT ||
        this.order.order_payments!.some(
          payment => payment!.payment_method!.type === method.type
        )
    );
  }

  public getPaymentMethods() {
    return this.paymentMethods.filter(type => type.enabled === 1);
  }

  public setPaymentMethods(item: PaymentMethod) {
    return this.paymentMethodsFiltered.filter(
      i =>
        !this.paymentModel.map(m => m.payment_method!.id).includes(i.id) ||
        i.id === item.id
    );
  }

  public async storeCredit() {
    this.typeRefund = false;
    this.resolve();
  }

  public async cash(currenMethod: PaymentMethod) {
    const data = {
      customer_id: this.currentCustomer.customer_id!,
      manager_id: this.user.id,
      type: TYPE_REMOVE_CASH.type,
      amount: +currenMethod.amount!,
      sub_type: TYPE_REMOVE_CASH.sub_type,
      description: TYPE_REMOVE_CASH.description
    };
    this.resolve();
  }

  public getStructurePrice() {
    return this.itemsSelected.map(item => {
      const structure = this.infoItemsSelected.items!.find(
        i => i.order_item_id === item.id
      );
      return { ...item, structure };
    });
  }

  public resolve() {
    this.printReceipt();
    window.onafterprint = () => {
      this.printBarcode();
    };
    this.$emit("resolve", true);
  }

  public printReceipt() {
    EventBus.$emit("print", {
      component: PrintReceiptRefundComponent,
      props: {
        operationUid: this.refund ? this.refund.operation_uid : null,
        order: this.order,
        methodsUsedinRefund: this.paymentModel,
        itemsRefunded: this.getStructurePrice(),
        structurePrice: this.infoItemsSelected
      }
    });
  }

  public async printBarcode() {
    if (this.printOnlyOnce) {
      return;
    }
    const products = await productService.findProductsforSKU(
      this.refund!.items.map(item => {
        return getParentSKU(item.sku);
      })
    );

    const batches = this.refund!.items.map((item: RefundHistoryItem) => {
      const productVariant = products.find(
        product => product.sku === getParentSKU(item.sku)
      );
      const refundInformation = this.refund!.metadata!.refund_information.find(
        rInfo => item.order_item_id === rInfo.refund_transaction.order_item_id
      );
      let trackingId: string = "";
      let batchUid = item.batch_uid;

      if (refundInformation) {
        trackingId = refundInformation.destination.tracking_id;
        batchUid = refundInformation.destination.destination;
      }
      return {
        batch_uid: batchUid,
        tracking_id: trackingId,
        product_variant: productVariant,
        product: productVariant,
        batch_sku: item.sku
      };
    });

    EventBus.$emit("print", {
      component: BarcodeListComponent,
      props: { batches, getPriceByBatches: true }
    });
    this.printOnlyOnce = true;
  }

  public remove(index: number) {
    this.paymentModel.splice(index, 1);
    this.validateAmounts();
  }

  public get missingAmount() {
    return +trunc(
      this.refundAmount -
        this.paymentModel.reduce((acc, cur) => {
          acc += +cur.amount!;
          return acc;
        }, 0)
    );
  }

  public async newMethod() {
    const newPayment = {
      amount: this.missingAmount,
      render_id: Date.now(),
      payment_method: {
        id: null
      }
    };
    this.paymentModel = [...this.paymentModel, { ...newPayment }];
    await this.validateAmounts();
  }

  public get getRulesError() {
    return {
      amount: (val: number, idx: number) => {
        if (
          idx < this.paymentModel.length &&
          this.paymentModel[idx].payment_method
        ) {
          const paymentMethod = this.paymentModel[idx].payment_method!.type!;
          const maxValueForPaymentMethod =
            this.paymentMethodMaxValueMap[paymentMethod] || Infinity;
          if (val > maxValueForPaymentMethod) {
            return this.$tc("modify_payment.amount_payment_method");
          }
        }
        return (
          (val && val > 0 && val <= this.refundAmount && !this.missingAmount) ||
          this.$t(this.invalidValueTextAmount).toString()
        );
      },
      method: [
        (val: { name: string; id: number }) => {
          return (
            (!!val.name && !!val.id) ||
            this.$t(this.invalidValueTextMethod).toString()
          );
        }
      ]
    };
  }

  public parseItemsSelected(): OrderItem[] {
    return this.itemsSelected.map(item => ({
      id: item.id,
      tracking_id: item.new_tacking_id,
      quantity: item.quantityToRefund,
      reason: ""
    }));
  }

  public async validateAmounts() {
    await (this.$refs.form as Vue & {
      validate: () => Promise<boolean>;
    }).validate();
  }

  public setPaymentAmountValue(event: KeyboardEvent, index: number) {
    const key = event.keyCode || event.which; // no option for this deprecated values
    const eventValue = (event.target as HTMLInputElement).value.toString();
    const modelValue = this.paymentModel[index].amount;

    if (
      (key < 48 || key > 57) &&
      (key !== 46 || eventValue.indexOf(".") !== -1)
    ) {
      event.preventDefault();
    }
    if (
      modelValue &&
      modelValue.toString().indexOf(".") > -1 &&
      modelValue.toString().split(".")[1].length > 1
    ) {
      event.preventDefault();
    }
  }

  public paymentBreakdown() {
    const arr = this.paymentModel.map((obj: any) => ({
      [obj.payment_method.name]: +obj.amount
    }));
    this.breakdown = Object.assign({}, ...arr);
    return this.breakdown;
  }

  public async runRefund(hasCashMethod?: PaymentMethod) {
    this.paymentBreakdown();
    const runMethodRefund = hasCashMethod ? this.cash : this.storeCredit;
    const refund = await orderService.refund(
      this.order.id!,
      this.parseItemsSelected(),
      this.breakdown,
      this.showLoading
    );
    if (refund) {
      this.refund = refund;
      if (refund.metadata!.integration_exception) {
        const metrcError = JSON.parse(refund.metadata!.integration_exception);
        this.showLoadingWindowsErrors(metrcError);
      } else {
        await runMethodRefund(hasCashMethod!);
        this.hideLoading();
        this.isLoading = false;
      }
    } else {
      this.hideLoading();
      this.isLoading = false;
    }
    this.resolve();
  }

  public async finish() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isLoading = true;
      const hasCashMethod = this.hasCashMethod();
      if (hasCashMethod) {
        const cashInTill = await moneyService.getCurrentUserTillReview();
        if (+cashInTill.cash_breakdown.expected > hasCashMethod.amount!) {
          await this.runRefund(hasCashMethod);
        } else {
          messagesService.showMessage(
            "fas fa-exclamation-circle",
            "refund_retail_sales.messages_checkout",
            "error"
          );
          this.isLoading = false;
        }
      } else {
        await this.runRefund();
      }
    }
  }

  public closeModal() {
    this.$emit("reject");
  }

  public created() {
    this.order.total = +trunc(this.order.total!);
    this.refundAmount = +trunc(this.infoItemsSelected.refund_total!);
    this.paymentModel = [
      {
        amount: this.refundAmount,
        id: Math.random(),
        payment_method: {}
      }
    ];
    this.paymentBreakdown();
    for (const method of this.order.order_payments || []) {
      const { amount } = method;
      const type = method.payment_method!.type as string;
      if (Object.keys(this.paymentMethodMaxValueMap).includes(type)) {
        this.paymentMethodMaxValueMap[type] = Math.max(
          amount,
          this.paymentMethodMaxValueMap[type]
        );
      }
    }
    // if total is equal to refund amount, then refund is not partial
    if (this.order.total === this.refundAmount) {
      let sum = 0;
      // iterate over paymentMethodMaxValueMap Adding payment method to payment model and adding to sum, if sum is less than refund amount add cash method
      this.paymentModel = [];
      for (const [key, value] of Object.entries(
        this.paymentMethodMaxValueMap
      )) {
        if (this.paymentMethodMaxValueMap[key] === 0) {
          continue;
        }
        this.paymentModel.push({
          id: Math.random(),
          amount: Math.min(value, this.refundAmount - sum),
          payment_method: pick(
            this.paymentMethodsFiltered.find(m => m.type === key),
            ["id", "name", "type"]
          )
        });
        sum += value;
      }
      const storeCreditMethod = this.order.order_payments!.find(
        m => m.payment_method!.type === "STORE_CREDIT"
      );
      if (storeCreditMethod && sum < this.refundAmount) {
        this.paymentModel.push({
          id: Math.random(),
          amount: storeCreditMethod.amount!,
          payment_method: pick(
            this.paymentMethodsFiltered.find(m => m.type === "STORE_CREDIT"),
            ["id", "name", "type"]
          )
        });
        sum += storeCreditMethod.amount!;
      }
      if (sum < this.refundAmount) {
        this.paymentModel.push({
          amount: +trunc(this.refundAmount - sum),
          id: Math.random(),
          payment_method: pick(
            this.paymentMethodsFiltered.find(
              m => m.type === TypePaymentMethod.CASH
            ),
            ["id", "name", "type"]
          )
        });
      }
    }
  }

  protected hasCashMethod(): PaymentMethod | undefined {
    return this.paymentModel.find(
      p => p.payment_method!.type === TypePaymentMethod.CASH
    );
  }

  protected showLoading() {
    if (this.hasMetrc && this.hasCannabisProduct) {
      this.$modals
        .load(LoadingWindowComponent, {
          closable: false,
          size: "fit",
          positionY: "center",
          positionX: "center",
          backdrop: true
        })
        .catch(async () => {
          const hasCashMethod = this.hasCashMethod();
          const runMethodRefund = hasCashMethod ? this.cash : this.storeCredit;
          await runMethodRefund(hasCashMethod!);
          this.isLoading = false;
        });
    }
  }

  protected showLoadingWindowsErrors(errorList: Array<{ message: string }>) {
    EventBus.$emit("mtrcLoadingEvent", {
      show: true,
      errorList
    });
  }

  protected hideLoading() {
    EventBus.$emit("mtrcLoadingEvent", {
      show: false
    });
  }
}
