import { LimitConfig } from "./location";

export interface RetailSettings {
  id?: number | null;
  location_id?: number | null;
  product_images_priority?: number | null;
  brand_images_priority?: number | null;
  strain_images_priority?: number | null;
  auto_add_item_scanner?: number | null;
  oldest_batch_first?: number | null;
  auto_print_when_sale_is_completed?: number | null;
  batch_prefix?: string | null;
  print_receipt_copies?: number | null;
  show_order_number?: number | null;
  payment_methods?: PaymentMethod[];
  allow_anonymous_customers?: number | null;
  default_image_product?: File;
  priority_array?: object[];
  limit_config?: LimitConfig;
  price_point_grouping?: 0 | 1;
  cumulative_pricing?: 0 | 1;
  retail_pre_tax_pricing?: 0 | 1;
  wholesale_pre_tax_pricing?: 0 | 1;
  sales_time_specify?: number;
  new_batch_on_refund?: boolean;
  integrations: Integrations | null;
  pharmacy_mode_enabled?: 0 | 1;
  rx_number?: string;
  [key: string]: any;
  doctor_tracking_enabled?: 0 | 1;
  sales_limit_view?: "REMAINING" | "USED";
  temporary_medical_card_enabled?: 0 | 1 | null;
  allow_call_in_pre_orders?: number | null;
  enable_in_store_order_fulfillment_flow?: number | null;
  allow_order_to_bypass_in_store_order_fulfillment?: number | null;
  print_pending_in_store_order_receipt_for_customer?: number | null;
  order_status?: number | null;
  delete_previous_orders?: number | null;
}

export interface Integrations {
  enable: Integrations | null;
  weed_maps: WeedmapsIntegration;
  metrc: { enable: boolean };
  biotrack_traceability: {
    enable: boolean;
    updated_at: string;
    updated_by: number;
    starting_inventory_date: Date;
  };
}
export interface WeedmapsIntegration {
  enable: boolean;
  updated_at: string;
  updated_by: number;
}

export interface PaymentMethod {
  id?: number | null;
  enabled?: number | null;
  location_id?: number | null;
  name?: string;
  type?: TypePaymentMethod;
  payment_method_id?: number | null;
  payment_method?: PaymentMethod;
  amount?: number;
}

export enum TypePaymentMethod {
  CASH = "CASH",
  DEBIT_CARD = "DEBIT_CARD",
  COUPON = "COUPON",
  CHECK = "CHECK",
  CREDIT_CARD = "CREDIT_CARD",
  STORE_CREDIT = "STORE_CREDIT"
}

export const retailSettingsDefault: RetailSettings = {
  sales_time_specify: 0,
  id: null,
  location_id: null,
  product_images_priority: 1,
  brand_images_priority: 2,
  strain_images_priority: 3,
  auto_add_item_scanner: 0,
  oldest_batch_first: 1,
  auto_print_when_sale_is_completed: 1,
  print_receipt_copies: 1,
  show_order_number: 1,
  new_batch_on_refund: true,
  payment_methods: [
    {
      payment_method_id: 1,
      enabled: 1,
      payment_method: { id: 1, name: "Debit Card" }
    },
    {
      payment_method_id: 2,
      enabled: 1,
      payment_method: { id: 2, name: "Credit Card" }
    },
    {
      payment_method_id: 3,
      enabled: 1,
      payment_method: { id: 3, name: "Cash" }
    },
    {
      payment_method_id: 5,
      enabled: 0,
      payment_method: { id: 5, name: "Check" }
    }
  ],
  allow_anonymous_customers: 1,
  integrations: null,
  pharmacy_mode_enabled: 0,
  rx_number: "",
  doctor_tracking_enabled: 0,
  sales_limit_view: "REMAINING",
  temporary_medical_card_enabled: 0,
  allow_call_in_pre_orders: 0,
  enable_in_store_order_fulfillment_flow: 0,
  allow_order_to_bypass_in_store_order_fulfillment: 0,
  print_pending_in_store_order_receipt_for_customer: 0
};
