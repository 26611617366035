import { render, staticRenderFns } from "./CreatenewbatchRefund.template.vue?vue&type=template&id=be8b7eae&scoped=true&"
var script = {}
import style0 from "./CreatenewbatchRefund.template.vue?vue&type=style&index=0&id=be8b7eae&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be8b7eae",
  null
  
)

export default component.exports