import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import PrintReceiptRefundComponent from "@/components/retail/pos/pointOfSale/posCustomerList/refund/printReceiptRefund/PrintReceiptRefund.component";
import BarcodeListComponent from "@/components/sharedComponents/print/barcodeList/barcodeList.component";
import TableProductsComponent from "@/components/sharedComponents/tableProducts/TableProductsComponent";
import { EventBus } from "@/event-bus";
import { currencyFilter } from "@/filters/currency.filter";
import { Customer } from "@/interfaces/customer";
import { Doctor } from "@/interfaces/doctor";
import { Location } from "@/interfaces/location";
import { Till } from "@/interfaces/money";
import {
  defaultFilterModel,
  FilterModel,
  Order,
  OrderItem,
  Refund,
  RefundActionCallback,
  RefundHistory,
  RefundHistoryItem,
  Refunditem,
  VoidOrderResponse
} from "@/interfaces/order";
import {
  PaymentMethod,
  RetailSettings,
  TypePaymentMethod
} from "@/interfaces/retailSettings";
import { User } from "@/interfaces/user";
import { store } from "@/internal";
import {
  RefundHistoryFields,
  RefundsHistoryTableMetadata
} from "@/metadata/order";
import { filterFormConfig } from "@/metadata/refundOrders";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import { integrationsEnabled, metrcEnabled } from "@/router.utils";
import { customerService } from "@/services/customer.service";
import { locationService } from "@/services/location.service";
import { messagesService } from "@/services/messages.service";
import { orderService } from "@/services/order.service";
import { OrderPayment } from "@/services/paymentMethods.service";
import { productService } from "@/services/product.service";
import { refundActionService } from "@/services/refund.action.service";
import { usersService } from "@/services/user.service";
import { getParentSKU } from "@/utils/batch-actions.utils";
import { truncate } from "@/utils/math.utils";
import {
  DynamicFormComponent,
  FormField,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import pick from "lodash/pick";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { UploadPmpPopupComponent } from "../pmpPopup/uploadPMP.component";
import { ModifyPaymentComponent } from "../Sales/ModifyPayment/ModifyPayment.component";
import Template from "./RefundHistory.template.vue";

const trunc = (num: number) => {
  return Math.trunc(num * 100) / 100;
};

const QUERY_DEFAULT = {
  filters: { ...defaultFilterModel },
  pagination: { page: 1, per_page: 10 }
};

@Component({
  mixins: [Template],
  components: {
    TableProductsComponent,
    TableComponent,
    DynamicFormComponent
  },
  inject: ["$changes"]
})
export default class RefundHistoryComponent extends Vue {
  @Getter("currentTill", { namespace: "UserModule" })
  public currentTill!: Till;
  @Getter("metrcEnabled", { namespace: "AuthModule" })
  public hasMetrc!: boolean;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Prop({ required: true })
  public refunds!: Refund[];
  @Prop({ required: true })
  public pagination!: TablePagination;
  @Prop({ required: true })
  public loading!: boolean;
  @Prop({ required: true })
  public pmpIntegration!: boolean;
  public paginate = orderService.paginationAction();
  public hasIntegrations = integrationsEnabled();
  public headers: TableHeader[] = [];
  public rowActions: TableAction[] = [];
  public customers: Customer[] = [];
  public users: User[] = [];
  public fieldsToShow: Array<{ label: string; value: string }> = [];
  public fieldsConfig: FormField[] | null = null;
  public currentFilter: FilterModel = { ...defaultFilterModel };
  public tableColors: string[] = [
    "#f2f2f2",
    "#f2f2f2",
    "#f2f2f2",
    "#ffffff",
    "#ffffff"
  ];
  public loadingExpand = false;
  public tempRefundObject!: Order | null;
  public actionCallbacks: RefundActionCallback = {
    reprintReceipts: this.reprintReceipts,
    printBarcodes: this.printBarcodes,
    hasMetrc: metrcEnabled,
    metrcDetail: this.metrcDetailsAction,
    syncMetrcStatus: this.syncMetrcStatusAction,
    uploadPmp: this.uploadPmp,
    payment: this.openModifyPaymentPopup,
    voidRefund: this.voidRefund
  };

  public missingDetails: string[] = [];
  public orderList: Order[] = [];
  public locationsList!: Location[];
  public doctorsList!: Doctor[];
  public dosage: boolean | undefined;
  public isPrescriptionDetailsNull: boolean = false;
  public doctorDetails: { [key: string]: boolean } = {
    doctorFirstName: false,
    doctorLastName: false,
    doctorNpi: false,
    doctorLicense: false,
    doctorDea: false
  };
  public preOrderEnum: { [key: string]: string } = {
    IN_STORE: "In-Store fulfillment order",
    ONLINE: "Online pre-order",
    CALL_IN: "Call-in pre-order"
  };
  public refundOrder!: Order | null;
  public refundInformation!: RefundHistory;

  public get refund() {
    if (this.refunds) {
      this.refunds.map(refund => {
        refund.items.map(refundItem => {
          if (
            refundItem.order_items!.batch_barcode_uid ===
              refundItem.order_items!.biotrack_traceability_id &&
            refundItem.order_items!.biotrack_traceability_id !== null
          ) {
            refundItem.batch_uid = refundItem.order_items!.biotrack_traceability_id;
          }
        });
      });
    }
    const refundsData = this.refunds.map(order => ({
      ...order,
      pmp_status: this.pmpStatus(order.items, order)
    }));
    return refundsData;
  }

  private get parsedRefunds() {
    this.refund.map(ref => {
      const refundSource = ref.items[0]!.order_items!.order!.pre_order;
      ref.source =
        refundSource && refundSource.source
          ? this.preOrderEnum[refundSource.source!]
          : "Order";
      ref.items.map(subItem => {
        subItem.price_raw =
          subItem.quantity * subItem.order_items!.unit_base_price!;
      });
    });
    return this.refund;
  }

  @Watch("pmpIntegration")
  public watchPmpIntegration() {
    this.fieldsConfig = filterFormConfig(
      this.customerSearch,
      this.userSearch,
      this.hasMetrc,
      this.pmpIntegration
    );
    this.headers = RefundsHistoryTableMetadata(
      this.hasMetrc,
      this.pmpIntegration
    );

    this.setRowActions();
  }
  public async setOrder(currentRefund: Refund) {
    if (!currentRefund.order) {
      this.loadingExpand = true;
      const order = await orderService.find(
        currentRefund.order_id,
        "orderPaymentRefunds.paymentMethod,orderItems"
      );
      this.refundOrder = order;
      this.$emit("addOrder", {
        operationUid: currentRefund.operation_uid,
        order
      });
    }
    this.loadingExpand = false;
  }

  public pmpStatus(orderItem: Refunditem[], order: Refund) {
    const data = orderItem.map(item => {
      return item.pmp_upload_status;
    });
    const orderCanabis = order.items!.map(item => {
      return item.order_items!.product_info!.marijuana;
    });
    if (
      !order.pmp_enabled &&
      orderCanabis.includes(1) &&
      !data.includes("NON_CANNABIS")
    ) {
      return "WARNING";
    } else if (
      !order.pmp_enabled &&
      orderCanabis.includes(1) &&
      data.includes("NON_CANNABIS")
    ) {
      return "WARNING";
    } else if (data.includes("ERROR")) {
      return "ERROR";
    } else if (data.includes("UPLOADED")) {
      return "UPLOADED";
    } else if (data.includes("NOT_UPLOADED")) {
      return "Not Uploaded";
    } else if (data.includes("NON_CANNABIS")) {
      return "Non-Cannabis";
    }
  }

  public async setRefundItemsInformation(currentRefund: RefundHistory) {
    if (
      currentRefund.items.filter(item => !!item.taxes_breakdown).length === 0
    ) {
      const refundInformation = await orderService.refundInformation(
        currentRefund.order_id,
        currentRefund.items.map(item => {
          return {
            id: item.order_item_id,
            available: item.quantity,
            quantity: item.quantity
          };
        }),
        false
      );
      this.refundInformation = refundInformation;
      if (refundInformation && Array.isArray(refundInformation.items)) {
        this.$emit("addRefunItemsinformation", {
          operationUid: currentRefund.operation_uid,
          items: refundInformation.items
        });
      }
    }
  }

  public async openModifyPaymentPopup(refund: Refund) {
    await this.setOrder(refund);
    const currentRefund = this.findCurrentRefund(refund);
    this.refundOrder!.total = +currencyFilter(currentRefund.total)
      .replace(/\$|,/g, "")
      .trim();
    let status = currentRefund.items[0].order_items!.order!.status;
    if (status === "PARTIALLY_REFUND") {
      status = "PARTIAL_REFUND";
    }
    this.refundOrder!.order_payments = this.refundOrder!.order_payment_refunds;
    if (this.refundOrder!.order_payments!.length === 1) {
      this.refundOrder!.order_payments![0].amount = +currencyFilter(
        String(currentRefund.total)
      ).replace(/\$|,|" "/g, "");
    }
    if (this.refundOrder) {
      this.$modals.load(
        ModifyPaymentComponent,
        {
          size: "fit",
          positionY: "top"
        },
        {
          item: this.refundOrder,
          type: status!.toLowerCase(),
          currentRefund
        }
      );
    }
  }

  public async voidRefund(arg: Refund) {
    await this.setOrder(arg);
    this.tempRefundObject = this.refundOrder;
    const pinService = new SecurityPinService();
    const pin = await pinService.ensure(
      `${this.$t("security_pin.title")}`,
      `${this.$t("security_pin.refund")}`
    );
    const showLoadingWindow = this.hasMetrc && this.hasCannabisProduct(arg);

    if (showLoadingWindow) {
      this.showLoadingWindow();
    } else {
      this.$emit("setLoadingTable", true);
    }
    const refundPaymentsArray = this.tempRefundObject!.order_payment_refunds!;

    const serverResponse = await orderService.refundsVoid(
      refundPaymentsArray[0].order_refund_id!,
      pin,
      showLoadingWindow
    );

    if (showLoadingWindow) {
      this.hideLoadingWindow(serverResponse);
    } else {
      this.$emit("setLoadingTable", false);
    }

    if (serverResponse.status === "success") {
      this.pagination.currentPage = 1;
      this.$emit("loadRefunds", QUERY_DEFAULT);
    }
  }

  public hasCannabisProduct(order: Refund): boolean {
    return (
      !!order.order_items &&
      !!order.order_items.find(
        item => !!item.product_info && item.product_info.marijuana === 1
      )
    );
  }

  public async uploadPmp(order: Order) {
    this.orderList.map((item: Order) => {
      if (item.id === order.id) {
        this.findMissingCustomerDetails(item);
        this.findMissingLocationDetails(item);
        item.order_items!.map((orderItem: OrderItem) => {
          if (
            orderItem.prescription_details === null &&
            orderItem.product_info!.marijuana
          ) {
            this.isPrescriptionDetailsNull = true;
          }
          if (orderItem.prescription_details !== null) {
            this.findMissingPrescriptionDetails(orderItem);
          }
        });
        this.getMissingPrescriptionDetails();
        this.getMissingDoctorDetails();
      }
    });
    if (!this.missingDetails.length) {
      const result = await orderService.uploadPmp(
        Number(order.order_id),
        order.operation_uid!
      );
      if (result && result.code === 200 && result.status === "success") {
        messagesService.renderSuccessMessage("pmp.upload_success_message");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        messagesService.renderErrorMessage("pmp.upload_fail_message");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } else {
      this.openPopupModal();
    }
    this.missingDetails = [];
  }

  public findMissingLocationDetails(order: Order) {
    this.locationsList.map((location: Location) => {
      if (location.id === order.location_id) {
        if (location.name === null) {
          this.missingDetails.push("label.location_name");
        }
        if (location.license_number === null) {
          this.missingDetails.push("label.location_license_number");
        }
        if (location.dea === null) {
          this.missingDetails.push("label.location_dea_number");
        }
      }
    });
  }

  public findMissingCustomerDetails(customerDetails: Order) {
    if (customerDetails.customer!.first_name === null) {
      this.missingDetails.push("label.customer_first_name");
    }
    if (customerDetails.customer!.last_name === null) {
      this.missingDetails.push("label.customer_last_name");
    }
    if (customerDetails.customer!.address === null) {
      this.missingDetails.push("customer_address");
    }
    if (customerDetails.customer!.city === null) {
      this.missingDetails.push("customer_city");
    }
    if (customerDetails.customer!.state === null) {
      this.missingDetails.push("customer_state");
    }
    if (customerDetails.customer!.gender === null) {
      this.missingDetails.push("customer_gender");
    }
    if (customerDetails.customer!.birthday === null) {
      this.missingDetails.push("label.customer_date_of_birth");
    }
    if (customerDetails.customer!.zip === null) {
      this.missingDetails.push("customer_zip");
    }
  }

  public findMissingDoctorDetails(orderItem: OrderItem) {
    this.doctorsList.map((doctor: Doctor) => {
      if (doctor.id === orderItem.prescription_details!.doctor_id) {
        if (doctor.first_name === null) {
          this.doctorDetails.doctorFirstName = true;
        }
        if (doctor.last_name === null) {
          this.doctorDetails.doctorLastName = true;
        }
        if (doctor.dea_number === null) {
          this.doctorDetails.doctorDea = true;
        }
        if (doctor.license_number === null) {
          this.doctorDetails.doctorLicense = true;
        }
        if (doctor.national_provider_identifier === null) {
          this.doctorDetails.doctorNpi = true;
        }
      }
    });
  }
  public getMissingDoctorDetails() {
    if (this.doctorDetails.doctorFirstName) {
      this.missingDetails.push("label.customer_doctor_first_name");
      this.doctorDetails.doctorFirstName = false;
    }
    if (this.doctorDetails.doctorLastName) {
      this.missingDetails.push("label.customer_doctor_last_name");
      this.doctorDetails.doctorLastName = false;
    }
    if (this.doctorDetails.doctorNpi) {
      this.missingDetails.push(
        "label.customer_doctor_national_provider_identifier"
      );
      this.doctorDetails.doctorNpi = false;
    }
    if (this.doctorDetails.doctorDea) {
      this.missingDetails.push("label.customer_doctor_dea_number");
      this.doctorDetails.doctorDea = false;
    }
    if (this.doctorDetails.doctorLicense) {
      this.missingDetails.push("label.customer_doctor_license_number");
      this.doctorDetails.doctorLicense = false;
    }
  }

  public findMissingPrescriptionDetails(orderItem: OrderItem) {
    if (orderItem.prescription_details!.quantity === null) {
      this.missingDetails.push("product_details.prescription_details");
    }
    if (
      orderItem.prescription_details!.dosage_timeframe_take === null ||
      orderItem.prescription_details!.dosage_timeframe_to === null
    ) {
      this.dosage = true;
    }
    this.findMissingDoctorDetails(orderItem);
  }

  public getMissingPrescriptionDetails() {
    if (this.isPrescriptionDetailsNull) {
      this.missingDetails.push("product_details.prescription_details");
    }
    if (this.dosage) {
      this.missingDetails.push("dosage");
    }
    this.isPrescriptionDetailsNull = false;
  }

  public openPopupModal() {
    this.$modals
      .load(
        UploadPmpPopupComponent,
        {
          size: "fit",
          positionY: "center"
        },
        {
          modalData: {
            data: this.missingDetails,
            icon: "fal fa-exclamation-triangle"
          }
        }
      )
      .catch(() => {
        // nothing to do
      });
  }

  public async setRowActions() {
    this.rowActions = refundActionService.getRowActions(
      this.actionCallbacks,
      this.pmpIntegration
    );
  }

  public async setProducts(currentRefund: RefundHistory) {
    if (!Array.isArray(currentRefund.products)) {
      const products = await productService.findProductsforSKU(
        currentRefund.items.map(item => {
          return getParentSKU(item.sku);
        })
      );

      this.$emit("addProducts", {
        operationUid: currentRefund.operation_uid,
        products
      });
    }
  }

  public refundExpand(data: { index: number; isOpening: boolean }) {
    if (data.isOpening) {
      this.refund[data.index].items!.map(items => {
        items.product_info = items.order_items!.product_info;
      });
      this.setOrder(this.refund[data.index]);
    }
  }

  public userSearch = async (scope: any) => {
    if (scope.search) {
      const response = await usersService.searchUsers(scope.search, false);
      this.users = response;
    } else {
      const response = await usersService.searchUsers(scope.value, true);
      this.users = response;
    }
    return this.users;
  };

  public customerSearch = async (scope: { newValue: string }) => {
    if (!scope.newValue || scope.newValue.length > 2) {
      const response = await customerService.filterCustomers({
        "q[first_name_or_last_name_contains]": scope.newValue
      });
      this.customers = response;
      return this.customers;
    }
    return [];
  };

  public onLoadRefunds(filters: FilterModel, pagination: TablePagination) {
    this.$emit("loadRefunds", {
      filters,
      pagination: {
        page: pagination.currentPage,
        per_page: pagination.itemsPerPage
      }
    });
  }

  public changePagination(pagination: TablePagination) {
    this.onLoadRefunds(this.currentFilter, pagination);
  }

  public sort(header: TableHeader) {
    this.$emit("sortTable", {
      header,
      type: "refunds",
      filters: this.currentFilter
    });
  }

  public onSetFilters(data: { model: FilterModel; modelField: string }) {
    this.currentFilter = data.model;
    this.onLoadRefunds(data.model, this.pagination);
  }

  public syncMetrcStatusAction(order: Order) {
    this.$emit("syncMetrcStatusAction", order, "refunds");
  }

  public metrcDetailsAction(order: Order) {
    this.$emit("metrcDetailsAction", order, true);
  }

  public async printBarcodes(refund: RefundHistory) {
    try {
      refund = this.findCurrentRefund(refund);
      await Promise.all([
        this.setOrder(refund as Refund),
        this.setProducts(refund)
      ]);
      const currentRefund = this.findCurrentRefund(refund);
      const batches = await this.getRefundedBatches(currentRefund);
      EventBus.$emit("print", {
        component: BarcodeListComponent,
        props: {
          batches,
          getPriceByBatches: true
        }
      });
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async reprintReceipts(refund: RefundHistory) {
    try {
      refund = this.findCurrentRefund(refund);
      await Promise.all([
        this.setOrder(refund as Refund),
        this.setRefundItemsInformation(refund)
      ]);
      const itemsSelected = this.getItemsSelected(refund);
      this.refundOrder!.user = refund.user!;
      EventBus.$emit("print", {
        component: PrintReceiptRefundComponent,
        props: {
          order: this.refundOrder,
          methodsUsedinRefund: this.getPaymentModel(this.refundOrder),
          itemsRefunded: this.getStructurePrice(
            this.refundInformation,
            itemsSelected
          ),
          structurePrice: this.refundInformation,
          operationUid: refund.operation_uid
        }
      });
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  protected hideLoadingWindow(serverResponse: VoidOrderResponse) {
    if (serverResponse.status === "error" && serverResponse.errors) {
      EventBus.$emit("mtrcLoadingEvent", {
        show: true,
        errorList: [{ message: serverResponse.errors[0].message }]
      });
    } else {
      EventBus.$emit("mtrcLoadingEvent", {
        show: false
      });
    }
  }

  protected showLoadingWindow() {
    this.$modals
      .load(LoadingWindowComponent, {
        closable: false,
        size: "fit",
        positionY: "center",
        positionX: "center",
        backdrop: true
      })
      .catch(() => {
        // nothing to do
      });
  }

  protected findCurrentRefund(refund: RefundHistory): RefundHistory {
    return {
      ...this.refund.find(r => r.operation_uid === refund.operation_uid)
    } as RefundHistory;
  }

  protected async getRefundedBatches(refund: RefundHistory) {
    const products = await productService.findProductsforSKU(
      refund!.items.map(item => {
        return getParentSKU(item.sku);
      })
    );
    const refundBatch = refund.items.map((refundItem: RefundHistoryItem, i) => {
      const orderItem = refundItem.order_items!;
      const productVariant = products.find(
        product => product.sku === getParentSKU(refundItem.sku)
      );
      const refundInformation = refund!.metadata!.refund_information.find(
        rInfo =>
          refundItem.order_item_id === rInfo.refund_transaction.order_item_id
      );
      let trackingId: string = "";
      let batchUid = refundItem.batch_uid;
      // @ts-ignore
      // in this case we are getting out for order_items as an object but in interface
      // order_items is defined as an Array. Also we can not create another interface
      // because order_items is everywhere used as an Array. So currently ignoring the error.
      const bioTrackTraceId = refundItem!.order_items!.biotrack_traceability_id;
      if (refundInformation) {
        trackingId = refundInformation.destination.tracking_id;
        batchUid = refundInformation.destination.destination;
      }
      return {
        biotrack_traceability_id: bioTrackTraceId,
        batch_uid: batchUid,
        tracking_id: trackingId,
        product_variant: productVariant,
        product: productVariant,
        batch_sku: refundItem.sku
      };
    });
    return refundBatch;
  }

  protected getItemsSelected(refund: RefundHistory): OrderItem[] {
    return (
      refund.items.map((rItem: RefundHistoryItem) => {
        return {
          ...rItem.order_items,
          quantityToRefund: rItem.quantity
        };
      }) || []
    );
  }

  protected getStructurePrice(refund: RefundHistory, itemsSelected: any[]) {
    return itemsSelected.map(oItem => {
      const structure = refund.items.find(
        rItem => rItem.order_item_id === oItem.id
      );
      return { ...oItem, structure };
    });
  }

  protected getPaymentMethodsFiltered() {
    const paymentMethods = store.getters["AuthModule/paymentMethods"];
    return paymentMethods.length
      ? paymentMethods.filter((p: PaymentMethod) =>
          [TypePaymentMethod.CASH, TypePaymentMethod.STORE_CREDIT].includes(
            p.type!
          )
        )
      : [];
  }

  protected getPaymentModel(refund: Order | null) {
    const array: OrderPayment[] = [];
    for (let i = 0; i < 1; i++) {
      let obj: OrderPayment;
      refund!.order_payment_refunds!.map(item => {
        obj = {
          amount: trunc(item.amount),
          id: Math.random(),
          payment_method_id: 0,
          payment_method: item.payment_method
        };
        array.push(obj);
      });
    }
    return array;
  }

  protected created() {
    // @ts-ignore
    this.$barcodeScanner.init((barcode: string) => {
      this.currentFilter.transactionId = barcode;
      this.onLoadRefunds(this.currentFilter, this.pagination);
    });
    this.fieldsConfig = filterFormConfig(
      this.customerSearch,
      this.userSearch,
      this.hasMetrc,
      this.pmpIntegration
    );
    this.setRowActions();

    if (!this.hasIntegrations) {
      this.fieldsConfig = this.fieldsConfig.filter(
        config => config.name !== "trcStatus"
      );
    }

    this.headers = RefundsHistoryTableMetadata(
      this.hasMetrc,
      this.pmpIntegration
    );
  }

  protected async mounted() {
    EventBus.$on("Orders_List", (orderList: Order[]) => {
      this.orderList = orderList;
    });
    this.fieldsToShow = RefundHistoryFields(
      this.retailSettings.stock_identification_type_in_pos
    );
    const doctorsList = await customerService.getDoctors();
    this.doctorsList = doctorsList;
    const locationList = await locationService.get();
    this.locationsList = locationList;
    EventBus.$on("paymentChangedRefund", () =>
      this.onLoadRefunds(this.currentFilter, this.pagination)
    );
  }

  protected beforeDestroy() {
    // @ts-ignore
    this.$barcodeScanner.destroy();
  }
}
