import { Refund } from "@/interfaces/order";
import { i18n } from "@/plugins/i18n";
import Vue from "vue";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

export interface OrderPayment {
  id?: number;
  order_refund_id?: number;
  payment_method_id: number;
  amount: number;
  payment_method?: Record<string, unknown>;
}

export class PaymentMethodsService extends HttpService {
  public async modifyPayment(
    orderId: number,
    payments: {
      order_payments: OrderPayment[];
      orderRefundIds?: number[];
    },
    type?: string,
    modifyfrom?: string
  ) {
    try {
      if (!(type === "refund" || type === "partial_refund")) {
        delete payments.orderRefundIds;
      }
      const request = Vue.axios({
        method: "POST",
        url: `/sale/orders/${orderId}/modify_payment`,
        data: payments,
        params: {
          type: type || null,
          modifyfrom
        }
      });
      const response = await request;
      messagesService.renderSuccessMessage(
        i18n.t("modify_payment.success").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
}

export const paymentMethodsService = new PaymentMethodsService();
