import { receiptDefault } from "@/components/template/receipt/default";
import { Customer, Profile } from "@/interfaces/customer";
import { PaymentMethod, Rule } from "@/interfaces/location";
import {
  Order,
  OrderInvoice,
  OrderItem,
  OrderItemST,
  Refund,
  RefundInformation,
  SimpleDiscount
} from "@/interfaces/order";
import { FieldDisplay, TemplateConfig } from "@/interfaces/templateEditor";
import { limitsService } from "@/services/limits.service";
import { orderService } from "@/services/order.service";
import { templatesService } from "@/services/templates.service";
import find from "lodash/find";
import forIn from "lodash/forIn";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import kebabCase from "lodash/kebabCase";
import sumBy from "lodash/sumBy";
import VueBarcode from "vue-barcode";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./PrintReceiptRefund.template.vue";

@Component({
  mixins: [Template],
  components: {
    VueBarcode
  }
})
export default class PrintReceiptRefund extends Vue {
  @Prop({ required: true })
  public itemsRefunded!: OrderItem[];
  @Prop({ required: true })
  public operationUid!: string;
  @Prop({ required: true })
  public order!: Order;
  @Prop({ required: true })
  public structurePrice!: RefundInformation;
  @Prop({ required: true })
  public methodsUsedinRefund!: PaymentMethod[];
  @Getter("currentLocation", { namespace: "AuthModule" })
  public location!: Location;
  public storeUser = {};
  public templateConfig: TemplateConfig = receiptDefault;
  public licenseNumber: number = 0;
  public customer: Customer | null = null;
  public customerLimits: Rule[] = [];
  public date = "";

  get discountTotal() {
    let totalDiscounts: number = 0;
    if (this.order.discounts!.length) {
      this.order.discounts!.forEach(
        discount => (totalDiscounts += discount.amount!)
      );
    }
    return totalDiscounts;
  }

  get customText() {
    return (
      (this.templateConfig.customText.fieldDisplay &&
        this.templateConfig.customText.fieldDisplay.value) ||
      ""
    );
  }

  get barcodeConfig() {
    return {
      textAlign:
        this.templateConfig.barcode.fieldDisplay!.style.textAlign || "center",
      fontSize: this.templateConfig.barcode.fieldDisplay!.style.fontSize || 16,
      height: this.templateConfig.barcode.fieldDisplay!.style.lineHeight || 32,
      width: 1,
      marginTop: this.templateConfig.barcode.fieldDisplay!.style.marginTop || 0,
      marginBottom:
        this.templateConfig.barcode.fieldDisplay!.style.marginBottom || 0,
      marginLeft: 0,
      marginRight: 0,
      format: "CODE128",
      tag: "svg",
      font: "Arial"
    };
  }
  public getTaxBreakdown() {
    interface Taxes {
      tax_id: number;
      name: string;
      amount: number;
    }
    const taxesBreakdown = this.itemsRefunded.reduce((taxes: Taxes[], item) => {
      taxes = [
        ...taxes,
        ...(get(item, "structure.taxes_breakdown") as Taxes[])
      ];
      return taxes;
    }, []);
    const taxesBreakdownGroups = groupBy(taxesBreakdown, "tax_id");
    return Object.keys(taxesBreakdownGroups).map(tax => ({
      tax_id: taxesBreakdownGroups[tax][0].tax_id,
      name: taxesBreakdownGroups[tax][0].name,
      amount: sumBy(taxesBreakdownGroups[tax], "amount")
    }));
  }

  public getMedicalCard() {
    const patientProfile: Profile | undefined = find(
      this.order.customer!.profiles,
      (profile: Profile) => {
        return profile.profile_type === "PATIENT";
      }
    );
    return patientProfile ? patientProfile.medical_card_number : "--";
  }

  public getUsableWeight(order: OrderItem) {
    return orderService.getUsableWeight(order);
  }
  public getFieldStyle(fieldDisplay: FieldDisplay) {
    if (fieldDisplay && fieldDisplay.style) {
      const styles: string[] = [];
      forIn(fieldDisplay.style, (val, k) => {
        if (!!+val) {
          val = val + "px";
        }
        styles.push(`${kebabCase(k)}: ${val}`);
      });
      return styles.join("; ");
    }
  }

  public getTotalDiscounAmount(item: OrderItemST): number {
    if (!item.structure.discounts_breakdown.length) {
      return 0;
    }
    const discounts = item.structure.discounts_breakdown.map(
      (discount: SimpleDiscount) => discount.amount
    );
    return discounts.reduce((sum: number, num: number) => sum + num);
  }

  public getTotal() {
    return sumBy(this.itemsRefunded, "totalPricesRefundItems");
  }

  public limitConsumed(limit: OrderInvoice) {
    return +(limit.amount - limit.consumed).toFixed(3);
  }

  protected async created() {
    const [template, orderUpdated] = await Promise.all([
      templatesService.getDefault("RECEIPT"),
      orderService.findById(this.order.id!, { embed: "customer" })
    ]);
    this.customer = orderUpdated.customer;
    this.date = orderUpdated.updated_at;
    // For StoreUser we're pulling the data from the order api itself
    this.storeUser = this.order!.user!;
    this.getTaxBreakdown();
    if (template) {
      this.templateConfig = template.config as TemplateConfig;
      if (this.templateConfig.remainingLimit.visible) {
        this.customerLimits = await limitsService.getCustomerConsumptions(
          this.order.customer_id!
        );
      }
    }
    this.$nextTick(() => this.$emit("readyToPrint"));
  }
}
