import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import TableProductsComponent from "@/components/sharedComponents/tableProducts/TableProductsComponent";
import { pusherEvents } from "@/enums/pusherEvents";
import { Customer } from "@/interfaces/customer";
import { Doctor } from "@/interfaces/doctor";
import { Location } from "@/interfaces/location";
import { Till } from "@/interfaces/money";
import {
  defaultFilterModel,
  FilterModel,
  Order,
  OrderActionCallback,
  OrderItem,
  VoidOrderResponse
} from "@/interfaces/order";
import { RetailSettings } from "@/interfaces/retailSettings";
import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { SalesHistoryTableMetadata } from "@/metadata/order";
import { filterFormConfig } from "@/metadata/refundOrders";
import { salesHistoryFieldsFunction } from "@/metadata/salesHistory";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import { integrationsEnabled, metrcEnabled } from "@/router.utils";
import { customerService } from "@/services/customer.service";
import { locationService } from "@/services/location.service";
import { messagesService } from "@/services/messages.service";
import { orderActionService } from "@/services/order.action.service";
import { orderService } from "@/services/order.service";
import { refundActionService } from "@/services/refund.action.service";
import { usersService } from "@/services/user.service";
import {
  DynamicFormComponent,
  FormField,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { UploadPmpPopupComponent } from "../pmpPopup/uploadPMP.component";
import { ModifyPaymentComponent } from "./ModifyPayment/ModifyPayment.component";
import Template from "./SalesHistory.template.vue";

const QUERY_DEFAULT = {
  filters: { ...defaultFilterModel },
  pagination: { page: 1, per_page: 10 }
};

@Component({
  mixins: [Template],
  components: {
    ModifyPaymentComponent,
    TableProductsComponent,
    TableComponent,
    DynamicFormComponent,
    UploadPmpPopupComponent
  },
  inject: ["$changes"]
})
export default class SalesHistoryComponent extends Vue {
  @Getter("currentTill", { namespace: "UserModule" })
  public currentTill!: Till;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Prop({ required: true })
  public orders!: Order[];
  @Prop({ required: true })
  public pagination!: TablePagination;
  @Prop({ required: true })
  @Prop({ required: true })
  public pmpIntegration!: boolean;
  public currentPage!: number;
  @Prop({ required: true })
  public loading!: boolean;
  public hasIntegrations = integrationsEnabled();
  public hasMetrc = metrcEnabled();
  public headers: TableHeader[] = [];
  public rowActions: TableAction[] = [];
  public customers: Customer[] = [];
  public users: User[] = [];
  public fieldsToShow: Array<{ label: string; value: string }> = [];
  public fieldsConfig: FormField[] | null = null;
  public currentFilter: FilterModel = { ...defaultFilterModel };
  public preOrderEnum: { [key: string]: string } = {
    IN_STORE: "In-Store fulfillment order",
    ONLINE: "Online pre-order",
    CALL_IN: "Call-in pre-order"
  };

  public tableColors: string[] = [
    "#f2f2f2",
    "#f2f2f2",
    "#f2f2f2",
    "#ffffff",
    "#ffffff"
  ];
  public filterModel: {
    [key: string]: any[] | string | null;
  } = {
    endingTime: null,
    terminalSelected: null,
    startingTime: null,
    customerSelected: null,
    searchField: null,
    user: null
  };
  public actionCallbacks: OrderActionCallback = {
    hasMetrc: metrcEnabled,
    refund: this.refund,
    payment: this.openModal,
    metrcDetail: this.metrcDetailsAction,
    syncMetrcStatus: this.syncMetrcStatusAction,
    voidSale: this.voidSale,
    uploadPmp: this.uploadPmp
  };
  public orderList: Order[] = [];
  public missingDetails: string[] = [];
  public dosage: boolean | undefined;
  public locationsList!: Location[];
  public isPrescriptionDetailsNull: boolean = false;
  public doctorsList!: Doctor[];
  public doctorDetails: { [key: string]: boolean } = {
    doctorFirstName: false,
    doctorLastName: false,
    doctorNpi: false,
    doctorLicense: false,
    doctorDea: false
  };

  public pmpStatus(order: Order) {
    const data = order.order_items!.map(item => {
      return item.pmp_upload_status;
    });
    const orderCanabis = order.order_items!.map(item => {
      return item.product_info!.marijuana;
    });
    if (
      !order.pmp_enabled &&
      orderCanabis.includes(1) &&
      !data.includes("NON_CANNABIS")
    ) {
      return "WARNING";
    } else if (
      !order.pmp_enabled &&
      orderCanabis.includes(1) &&
      data.includes("NON_CANNABIS")
    ) {
      return "WARNING";
    } else if (data.includes("ERROR")) {
      return "ERROR";
    } else if (data.includes("UPLOADED")) {
      return "UPLOADED";
    } else if (data.includes("NOT_UPLOADED")) {
      return "Not Uploaded";
    } else if (data.includes("NON_CANNABIS")) {
      return "Non-Cannabis";
    }
  }

  @Watch("pmpIntegration")
  public watchPmpIntegration() {
    this.fieldsConfig = filterFormConfig(
      this.customerSearch,
      this.userSearch,
      this.hasMetrc,
      this.pmpIntegration
    );
    this.headers = SalesHistoryTableMetadata(
      this.hasMetrc,
      this.pmpIntegration
    );
    this.setRowActions();
  }

  public async uploadPmp(order: Order) {
    this.orderList.map((item: Order) => {
      if (item.id === order.id) {
        this.findMissingCustomerDetails(item);
        this.findMissingLocationDetails(item);

        item.order_items!.map((orderItem: OrderItem) => {
          if (
            orderItem.prescription_details === null &&
            orderItem.product_info!.marijuana
          ) {
            this.isPrescriptionDetailsNull = true;
          }
          if (orderItem.prescription_details !== null) {
            this.findMissingPrescriptionDetails(orderItem);
          }
        });
        this.getMissingPrescriptionDetails();
        this.getMissingDoctorDetails();
      }
    });
    if (!this.missingDetails.length) {
      const result = await orderService.uploadPmp(Number(order.id));
      if (result && result.code === 200 && result.status === "success") {
        messagesService.renderSuccessMessage("pmp.upload_success_message");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        messagesService.renderErrorMessage("pmp.upload_fail_message");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } else {
      this.openPopupModal();
    }
    this.missingDetails = [];
  }

  public findMissingLocationDetails(order: Order) {
    this.locationsList.map((location: Location) => {
      if (location.id === order.location_id) {
        if (location.name === null) {
          this.missingDetails.push("label.location_name");
        }
        if (location.license_number === null) {
          this.missingDetails.push("label.location_license_number");
        }
        if (location.dea === null) {
          this.missingDetails.push("label.location_dea_number");
        }
      }
    });
  }

  public findMissingCustomerDetails(customerDetails: Order) {
    if (customerDetails.customer!.first_name === null) {
      this.missingDetails.push("label.customer_first_name");
    }
    if (customerDetails.customer!.last_name === null) {
      this.missingDetails.push("label.customer_last_name");
    }
    if (customerDetails.customer!.address === null) {
      this.missingDetails.push("customer_address");
    }
    if (customerDetails.customer!.city === null) {
      this.missingDetails.push("customer_city");
    }
    if (customerDetails.customer!.state === null) {
      this.missingDetails.push("customer_state");
    }
    if (customerDetails.customer!.gender === null) {
      this.missingDetails.push("customer_gender");
    }
    if (customerDetails.customer!.birthday === null) {
      this.missingDetails.push("label.customer_date_of_birth");
    }
    if (customerDetails.customer!.zip === null) {
      this.missingDetails.push("customer_zip");
    }
  }

  public findMissingDoctorDetails(orderItem: OrderItem) {
    this.doctorsList.map((doctor: Doctor) => {
      if (doctor.id === orderItem.prescription_details!.doctor_id) {
        if (doctor.first_name === null) {
          this.doctorDetails.doctorFirstName = true;
        }
        if (doctor.last_name === null) {
          this.doctorDetails.doctorLastName = true;
        }
        if (doctor.dea_number === null) {
          this.doctorDetails.doctorDea = true;
        }
        if (doctor.license_number === null) {
          this.doctorDetails.doctorLicense = true;
        }
        if (doctor.national_provider_identifier === null) {
          this.doctorDetails.doctorNpi = true;
        }
      }
    });
  }
  public getMissingDoctorDetails() {
    if (this.doctorDetails.doctorFirstName) {
      this.missingDetails.push("label.customer_doctor_first_name");
      this.doctorDetails.doctorFirstName = false;
    }
    if (this.doctorDetails.doctorLastName) {
      this.missingDetails.push("label.customer_doctor_last_name");
      this.doctorDetails.doctorLastName = false;
    }
    if (this.doctorDetails.doctorNpi) {
      this.missingDetails.push(
        "label.customer_doctor_national_provider_identifier"
      );
      this.doctorDetails.doctorNpi = false;
    }
    if (this.doctorDetails.doctorDea) {
      this.missingDetails.push("label.customer_doctor_dea_number");
      this.doctorDetails.doctorDea = false;
    }
    if (this.doctorDetails.doctorLicense) {
      this.missingDetails.push("label.customer_doctor_license_number");
      this.doctorDetails.doctorLicense = false;
    }
  }

  public findMissingPrescriptionDetails(orderItem: OrderItem) {
    if (orderItem.prescription_details!.quantity === null) {
      this.missingDetails.push("product_details.prescription_details");
    }
    if (
      orderItem.prescription_details!.dosage_timeframe_take === null ||
      orderItem.prescription_details!.dosage_timeframe_to === null
    ) {
      this.dosage = true;
    }
    this.findMissingDoctorDetails(orderItem);
  }

  public getMissingPrescriptionDetails() {
    if (this.isPrescriptionDetailsNull) {
      this.missingDetails.push("product_details.prescription_details");
    }
    if (this.dosage) {
      this.missingDetails.push("dosage");
    }
    this.isPrescriptionDetailsNull = false;
  }

  public openPopupModal() {
    this.$modals
      .load(
        UploadPmpPopupComponent,
        {
          size: "fit",
          positionY: "center"
        },
        {
          modalData: {
            data: this.missingDetails,
            icon: "fal fa-exclamation-triangle"
          }
        }
      )
      .catch(() => {
        // nothing to do
      });
  }

  private get parsedOrders(): Order[] {
    return this.orders.map(order => ({
      ...order,
      metrc_reciept_number: `${order.status}`,
      total: order.total,
      sub_total: order.sub_total,
      tax_total: order.tax_total,
      line_order_discounts: order.line_order_discounts,
      source: order.pre_order
        ? this.preOrderEnum[order.pre_order!.source]
        : "Order",
      pmp_status: this.pmpStatus(order),
      order_items:
        order.order_items &&
        order.order_items.map(item => ({
          ...item,
          available: +item.available!.toFixed(2),
          price_final: item.price_final,
          quantityFormated: `${item.quantity}${item.product_info &&
            item.product_info.product_unit}`
        }))
    }));
  }

  public userSearch = async (scope: any) => {
    if (scope.search) {
      const response = await usersService.searchUsers(scope.search, false);
      this.users = response;
    } else {
      const response = await usersService.searchUsers(scope.value, true);
      this.users = response;
    }
    return this.users;
  };

  public customerSearch = async (scope: { newValue: string }) => {
    if (!scope.newValue || scope.newValue.length > 2) {
      const response = await customerService.filterCustomers({
        "q[first_name_or_last_name_contains]": scope.newValue
      });
      this.customers = response;
      return this.customers;
    }
  };

  public onLoadOrders(filters: FilterModel, pagination: TablePagination) {
    this.$emit("loadOrders", {
      filters,
      pagination: {
        page: pagination.currentPage,
        per_page: pagination.itemsPerPage
      }
    });
  }

  public changePagination(pagination: TablePagination) {
    this.currentPage = pagination.currentPage;
    this.onLoadOrders(this.currentFilter, pagination);
  }

  public sort(header: TableHeader) {
    this.$emit("sortTable", {
      header,
      type: "sales",
      filters: this.currentFilter
    });
  }

  public openModal(item: Order) {
    this.$modals
      .load(
        ModifyPaymentComponent,
        {
          size: "fit",
          positionY: "top"
        },
        {
          item
        }
      )
      .then(
        () => {
          this.onLoadOrders(this.currentFilter, this.pagination);
        },
        () => {
          // Nothing to do
        }
      );
  }

  public async voidSale(arg: Order) {
    const pinService = new SecurityPinService();
    const pin = await pinService.ensure(
      "Security Pin",
      "You must enter a valid PIN code to void this sale"
    );
    const showLoadingWindow = this.hasMetrc && this.hasCannabisProduct(arg);

    if (showLoadingWindow) {
      this.showLoadingWindow();
    } else {
      this.$emit("setLoadingTable", true);
    }

    const serverResponse = await orderService.void(
      arg.id!,
      pin,
      showLoadingWindow
    );

    if (showLoadingWindow) {
      this.hideLoadingWindow(serverResponse);
    } else {
      this.$emit("setLoadingTable", false);
    }

    if (serverResponse.status === "success") {
      this.$emit("loadOrders", {
        filters: { ...this.currentFilter },
        pagination: { page: this.currentPage, per_page: 10 }
      });
    }
  }

  public syncMetrcStatusAction(order: Order) {
    this.$emit("syncMetrcStatusAction", order, "sales");
  }
  public metrcDetailsAction(order: Order) {
    this.$emit("metrcDetailsAction", order);
  }

  public onSetFilters(data: { model: FilterModel; modelField: string }) {
    this.currentFilter = data.model;
    this.onLoadOrders(data.model, this.pagination);
  }

  public refund(order: Order) {
    if (!this.currentTill) {
      return;
    }
    refundActionService.modalRefund(order);
  }

  public setRowActions() {
    this.rowActions = orderActionService.getRowActions(
      this.actionCallbacks,
      this.pmpIntegration
    );
  }

  protected hasCannabisProduct(order: Order): boolean {
    return (
      !!order.order_items &&
      !!order.order_items.find(
        item => !!item.product_info && item.product_info.marijuana === 1
      )
    );
  }

  protected showLoadingWindow() {
    this.$modals
      .load(LoadingWindowComponent, {
        closable: false,
        size: "fit",
        positionY: "center",
        positionX: "center",
        backdrop: true
      })
      .catch(() => {
        // nothing to do
      });
  }

  protected hideLoadingWindow(serverResponse: VoidOrderResponse) {
    if (serverResponse.status === "error" && serverResponse.errors) {
      EventBus.$emit("mtrcLoadingEvent", {
        show: true,
        errorList: [{ message: serverResponse.errors[0].message }]
      });
    } else {
      EventBus.$emit("mtrcLoadingEvent", {
        show: false
      });
    }
  }

  protected created() {
    EventBus.$on("setOrder", (numberOrder: string) => {
      this.currentFilter = { ...defaultFilterModel };
      this.currentFilter.transactionId = numberOrder;
      this.onLoadOrders(this.currentFilter, this.pagination);
    });
    this.fieldsConfig = filterFormConfig(
      this.customerSearch,
      this.userSearch,
      this.hasMetrc,
      this.pmpIntegration
    );
    if (!this.hasIntegrations) {
      this.fieldsConfig = this.fieldsConfig.filter(
        config => config.name !== "trcStatus"
      );
    }

    this.setRowActions();

    // @ts-ignore
    this.$barcodeScanner.init((barcode: string) => {
      this.currentFilter.transactionId = barcode;
      this.onLoadOrders(this.currentFilter, this.pagination);
    });

    this.headers = SalesHistoryTableMetadata(
      this.hasMetrc,
      this.pmpIntegration
    );
  }
  protected async mounted() {
    this.$changes.watch(
      [pusherEvents.orderVoid, pusherEvents.orderPayment],
      () => this.onLoadOrders(this.currentFilter, this.pagination)
    );
    refundActionService.$on("loadOrders", () => {
      this.onLoadOrders(this.currentFilter, this.pagination);
    });
    this.fieldsToShow = salesHistoryFieldsFunction(
      this.retailSettings.stock_identification_type_in_pos
    );
    EventBus.$on("Orders_List", (orderList: Order[]) => {
      this.orderList = orderList;
    });
    const doctorsList = await customerService.getDoctors();
    this.doctorsList = doctorsList;
    const locationList = await locationService.get();
    this.locationsList = locationList;
  }

  protected beforeDestroy() {
    // @ts-ignore
    this.$barcodeScanner.destroy();
    EventBus.$off("Orders_List");
  }
}
