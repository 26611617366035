import CreatenewbatchRefund from "@/components/retail/pos/pointOfSale/posCustomerList/refund/CreatenewbatchRefund/CreatenewbatchRefund.component";
import { Customer } from "@/interfaces/customer";
import {
  DEFAULT_TOTALS,
  Order,
  OrderItem,
  RefundInformation
} from "@/interfaces/order";
import { RetailSettings } from "@/interfaces/retailSettings";
import { i18n } from "@/plugins/i18n";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import RefunDecidedModal from "../refundDecideModal/RefundDecideModal.component";
import RefunOrderdModal from "../refundOrderModal/RefundOrderModal.component";
import Template from "./RefundModal.template.vue";

@Component({
  mixins: [Template],
  components: {
    RefunOrderdModal,
    CreatenewbatchRefund,
    RefunDecidedModal
  },
  filters: {
    stepLabel(value: number) {
      return value + 1;
    }
  }
})
export default class RefundModalComponent extends Vue {
  @Prop({ required: true })
  public currentCustomer!: Customer | null;
  @Prop({ required: true })
  public order!: Order;

  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  @Getter("metrcEnabled", { namespace: "AuthModule" })
  public hasMetrc!: boolean;

  public currentStep: number = 1;
  public orderItems: OrderItem[] = [];
  public orderItemsSelected: OrderItem[] = [];
  public orderItemsSelectedInfo: RefundInformation = { ...DEFAULT_TOTALS };

  public previousStep() {
    this.currentStep--;
  }

  public updateItemsSelected(itemsSelected: OrderItem[], resolve?: () => void) {
    this.orderItemsSelected = itemsSelected;
    if (resolve && typeof resolve === "function") {
      resolve();
    }
  }

  public selectRefund(data: {
    orderItems: OrderItem[];
    itemsSelected: OrderItem[];
    infoItemsSelected: RefundInformation;
  }) {
    this.orderItems = data.orderItems;
    this.orderItemsSelected = data.itemsSelected;
    this.orderItemsSelectedInfo = data.infoItemsSelected;
    this.nextStep();
  }

  public confirmCreateNewBatch(itemsSelected: OrderItem[]) {
    this.updateItemsSelected(itemsSelected);
    this.nextStep();
  }

  public finishRefund(printWasAllowed: boolean) {
    this.$emit("resolve", {
      printWasAllowed,
      data: {
        itemsSelected: this.orderItemsSelected,
        infoItemsSelected: this.orderItemsSelectedInfo
      }
    });
  }

  public cancel() {
    this.$emit("reject");
  }

  public nextStep() {
    this.currentStep++;
  }

  public get hasCannabisProductSelected(): boolean {
    return !!this.orderItemsSelected.find(
      item => item.product_info!.marijuana === 1
    );
  }

  public get steps() {
    return [
      {
        title: i18n.t("refund_retail_sales.select_refund"),
        component: RefunOrderdModal,
        props: {
          order: this.order,
          orderItems: this.orderItems,
          itemsSelected: this.orderItemsSelected,
          itemsSelectedInfo: this.orderItemsSelectedInfo
        },
        actions: {
          updateSelecteds: this.updateItemsSelected,
          selectRefund: this.selectRefund,
          cancel: this.cancel
        }
      },
      ...(this.currentRetailSettings.new_batch_on_refund &&
      this.hasCannabisProductSelected
        ? [
            {
              title: i18n.t("refund_retail_sales.create_new_batch"),
              component: CreatenewbatchRefund,
              props: {
                itemsSelected: this.orderItemsSelected,
                infoItemsSelected: this.orderItemsSelectedInfo
              },
              actions: {
                resolve: this.confirmCreateNewBatch,
                reject: this.previousStep,
                skipStep: this.nextStep
              }
            }
          ]
        : []),
      {
        title: i18n.t("refund_retail_sales.payment"),
        component: RefunDecidedModal,
        props: {
          itemsSelected: this.orderItemsSelected,
          infoItemsSelected: this.orderItemsSelectedInfo,
          order: this.order,
          currentCustomer: this.order.customer,
          hasCannabisProduct: this.hasCannabisProductSelected,
          hasMetrc: this.hasMetrc
        },
        actions: {
          resolve: this.finishRefund,
          reject: this.previousStep
        }
      }
    ];
  }

  public created() {
    this.orderItems =
      this.order.order_items!.map(item => {
        return {
          ...item,
          quantityToRefund: item.available,
          new_tacking_id: ""
        };
      }) || [];
  }
}
