import { MetrcDetailsComponent } from "@/components/retail/salesHistory/MetrcDetails/MetrcDetails.component";
import { Order } from "@/interfaces/order";
import Vue from "vue";

class MtrcSalesActionService extends Vue {
  public async action(order: Order, isRefund = false) {
    try {
      const response = await this.$modals.load(
        MetrcDetailsComponent,
        {
          size: "fit",
          positionY: "top"
        },
        {
          order,
          isRefund
        }
      );
      return response;
    } catch (error) {
      return null;
    }
  }
}

export const metrcSalesActionService = new MtrcSalesActionService();
