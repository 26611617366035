import { render, staticRenderFns } from "./PrintReceiptRefund.template.vue?vue&type=template&id=5d2d5d6d&scoped=true&"
var script = {}
import style0 from "./PrintReceiptRefund.template.vue?vue&type=style&index=0&id=5d2d5d6d&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d2d5d6d",
  null
  
)

export default component.exports